import React, { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { useElementSize } from '@mantine/hooks';
import css from './GenericCarousel.module.css';

const GenericCarouselComponent = (props) => {
  const {
    minimumSlideWidth,
    maximumNumberOfSlides,
    columnGap,
    largeTitle,
    slides,
    mappingSlideFunction,
    mappingSlideFunctionParams,
    carouselTitle,
    carouselSubtitle,
  } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    containScroll: 'trimSnaps',
    align: 'start',
    slidesToScroll: 1,
    watchResize: true,
  });
  const [showPrevBtn, setShowPrevBtn] = useState(true);
  const [showNextBtn, setShowNextBtn] = useState(true);
  const onSelect = useCallback(() => {
    setShowPrevBtn(emblaApi.canScrollPrev());
    setShowNextBtn(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  useEffect(() => {
    if (emblaApi) emblaApi.on('init', onSelect);
  }, [emblaApi, onSelect]);

  useEffect(() => {
    if (emblaApi) emblaApi.on('reInit', onSelect);
  }, [emblaApi, onSelect]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const { ref, width: carouselWidth } = useElementSize(); // carousel element width

  const getNumberOfSlides = () => {
    if (carouselWidth > 0) {
      // To avoid calculations while browser hasn't determined carousel's size
      // Number of slides = ((carouselWidth - (number of slides - 1) * columnGap)) divided (integer division) by minimumSlideWidth
      // simplified gives, Number of slides = (carouselWidth + columnGap) / (minimumSlideWidth + columnGap), where / is integer division
      // For example: For a viewport of 768, carouselWidth is 720 (768px - 24px * 2) and then Number of slides is 2, (720+24) / (300+24) =  2.293
      let numberOfSlides = Math.floor(
        (carouselWidth + columnGap) / (minimumSlideWidth + columnGap)
      );
      numberOfSlides =
        numberOfSlides > maximumNumberOfSlides ? maximumNumberOfSlides : numberOfSlides;
      return numberOfSlides === 0 ? 1 : numberOfSlides;
    }
    return 1;
  };

  const getSlidesToScroll = () => {
    const numberOfSlides = getNumberOfSlides();
    return numberOfSlides;
  };

  const getSlideWidth = () => {
    const numberOfSlides = getNumberOfSlides();
    return Math.round((carouselWidth - (numberOfSlides - 1) * columnGap) / numberOfSlides);
  };


  useEffect(() => {
    const slidesToScroll = getSlidesToScroll();
    if (emblaApi) {
      emblaApi.reInit({
        loop: false,
        containScroll: 'trimSnaps',
        align: 'start',
        slidesToScroll: slidesToScroll,
        watchResize: true,
        canScrollNext: showNextBtn,
        canScrollPrev: showPrevBtn
      });
    }
  }, [carouselWidth]);


  return (
    <>
      {slides !== null && slides.length > 0 ? (
        <div className={css.emblaCarouselWrapper} ref={ref}>
          {largeTitle && carouselTitle != '' ? (
            <h1 className={css.carouselLargeTitle}>
              {' '}
              <FormattedMessage id={carouselTitle} />
            </h1>
          ) : (
            <h2 className={css.carouselSmallTitle}>
              {' '}
              <FormattedMessage id={carouselTitle} />
            </h2>
          )}
          {carouselSubtitle && carouselSubtitle != '' ? (
            <h4 className={css.carouselSubtitle}>
              <FormattedMessage id={carouselSubtitle} />
            </h4>
          ) : null}
          <div className={css.embla}>
            <div className={css.embla_viewport} ref={emblaRef}>
              <div
                className={css.embla__container}
                style={{ gridAutoColumns: getSlideWidth(), columnGap: columnGap + 'px' }}
              >
                {carouselWidth > 0 && slides
                  ? slides.map((item) => (
                      <div className={css.embla__slide} key={item.id}>
                        {mappingSlideFunction({
                          ...item,
                          ...mappingSlideFunctionParams,
                          width: getSlideWidth(),
                        })}
                      </div>
                    ))
                  : null}
              </div>
              {carouselWidth > 0 && showPrevBtn ? (
                <button
                  /* style={{ top: Math.floor((0.6667 * getSlideWidth()) / 2) }} */
                  className={css.embla__button_prev}
                  onClick={scrollPrev}
                >
                  <svg
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1rem"
                    height="1rem"
                    style={{ transform: 'rotate(90deg)' }}
                  >
                    <path
                      d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              ) : null}
              {carouselWidth > 0 && showNextBtn ? (
                <button
                  /* style={{ top: Math.floor((0.6667 * getSlideWidth()) / 2) }} */
                  className={css.embla__button_next}
                  onClick={scrollNext}
                >
                  <svg
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1rem"
                    height="1rem"
                    style={{ transform: 'rotate(-90deg)' }}
                  >
                    <path
                      d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const GenericCarousel = injectIntl(GenericCarouselComponent);
export default React.memo(GenericCarousel);
