import React, { useState, useEffect } from 'react';
import { injectIntl } from '../../util/reactIntl';
import recombee from 'recombee-js-api-client/dist/recombee-api-client';
import RecombeeListingCard from './RecombeeListingCard';
import GenericCarousel from '../GenericCarousel/GenericCarousel';

import css from './RecombeeRecommendations.module.css';

// card component
function Card(item) {
  const { id, values, intl, recommId, width } = item;
  const {
    duration,
    imageUrl,
    pickupIncluded,
    price,
    title,
  } = values;

  return (
    <div className={css.listingCard}>
      <RecombeeListingCard
        title={title}
        imageUrl={imageUrl}
        listingId={id}
        intl={intl}
        duration={duration}
        pickupIncluded={pickupIncluded}
        price={price}
        recommId={recommId}
        width={width}
      />
    </div>
  );
}

const EmblaCarouselComponent = (props) => {
  const {
    count,
    intl,
    itemToUser,
    largeTitle,
    listingId,
    scenario,
    userIdentification,
    region,
  } = props;

  const [slides, setSlides] = useState(null);
  const [loading, setLoading] = useState(true);

  const recombeeDB = process.env.REACT_APP_RECOMBEE_DB ? process.env.REACT_APP_RECOMBEE_DB : null;
  const recombeeToken = process.env.REACT_APP_RECOMBEE_PUBLIC_TOKEN
    ? process.env.REACT_APP_RECOMBEE_PUBLIC_TOKEN
    : null;

  useEffect(() => {
    let finished = false;
    async function fetchRecomms() {
      if (userIdentification === '') return;
      var client = new recombee.ApiClient(recombeeDB, recombeeToken, { region: 'eu-west' });
      const optionParams =
        region !== null
          ? {
              // optional parameters:
              scenario: scenario,
              returnProperties: true,
              filter: "'region' == \"" + region + '"',
            }
          : {
              // optional parameters:
              scenario: scenario,
              returnProperties: true,
            };
      if (itemToUser && loading) {
        const response = await client.send(
          new recombee.RecommendItemsToUser(userIdentification, count, optionParams)
        );
        const test = await response;
        finished = true;
        setSlides(test);
        setLoading(false);
      } else if (itemToUser === false && loading) {
        const response = await client.send(
          new recombee.RecommendItemsToItem(listingId.uuid, userIdentification, count, optionParams)
        );
        const test = await response;
        finished = true;
        setSlides(test);
        setLoading(false);
      }
    }

    if (!finished) {
      fetchRecomms().catch((error) => {
        console.log(error);
      });
    }

    return () => {
      finished = true;
    };
  }, [loading, userIdentification, itemToUser, region]);

  const scenarioTitle = scenario.split('-');
  const carouselTitle = `RecombeeRecommendations.${scenarioTitle[1]}`;
  const carouselSubtitle = `RecombeeRecommendations.${scenarioTitle[1]}Subtitle`;

  return (
    <>
    {slides!=null ?
      <GenericCarousel
        minimumSlideWidth={300}
        maximumNumberOfSlides={6}
        columnGap={24}
        largeTitle={largeTitle}
        slides={slides.recomms}
        mappingSlideFunction={Card}
        mappingSlideFunctionParams={{recommId:slides.recommId, intl:intl}}
        carouselTitle={carouselTitle}
        carouselSubtitle={carouselSubtitle}
      />
      : null }
    </>
  );
};

const EmblaCarousel = injectIntl(EmblaCarouselComponent);
export default React.memo(EmblaCarousel);
