import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';
import { klaviyoGetProfile, klaviyoUpdateProfile } from '../../util/api';


// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/ContactDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/ContactDetailsPage/SAVE_PHONE_NUMBER_ERROR';
export const SAVE_MARKETING_EMAIL_ERROR = 'app/ContactDetailsPage/SAVE_MARKETING_EMAIL_ERROR'

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

export const RESET_PASSWORD_REQUEST = 'app/ContactDetailsPage/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/ContactDetailsPage/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/ContactDetailsPage/RESET_PASSWORD_ERROR';

export const SAVE_NEW_BOOKING_SMS_NOTIFICATION_REQUEST = 'app/ContactDetailsPage/SAVE_NEW_BOOKING_SMS_NOTIFICATION_REQUEST';
export const SAVE_NEW_BOOKING_SMS_NOTIFICATION_SUCCESS = 'app/ContactDetailsPage/SAVE_NEW_BOOKING_SMS_NOTIFICATION_SUCCESS';
export const SAVE_NEW_BOOKING_SMS_NOTIFICATION_ERROR = 'app/ContactDetailsPage/SAVE_NEW_BOOKING_SMS_NOTIFICATION_ERROR';

export const SAVE_NEW_BOOKING_WHATSAPP_NOTIFICATION_REQUEST = 'app/ContactDetailsPage/SAVE_NEW_BOOKING_WHATSAPP_NOTIFICATION_REQUEST';
export const SAVE_NEW_BOOKING_WHATSAPP_NOTIFICATION_SUCCESS = 'app/ContactDetailsPage/SAVE_NEW_BOOKING_WHATSAPP_NOTIFICATION_SUCCESS';
export const SAVE_NEW_BOOKING_WHATSAPP_NOTIFICATION_ERROR = 'app/ContactDetailsPage/SAVE_NEW_BOOKING_WHATSAPP_NOTIFICATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveMarketingEmailError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
  resetPasswordInProgress: false,
  resetPasswordError: null,
  saveNewBookingSmsNotificationError: null,
  saveNewBookingWhatsappNotificationError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
    case SAVE_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
    case SAVE_PHONE_NUMBER_ERROR:
      return { ...state, saveContactDetailsInProgress: false, savePhoneNumberError: payload };
    case SAVE_MARKETING_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveMarketingEmailError: payload }

    case SAVE_CONTACT_DETAILS_CLEAR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        savePhoneNumberError: null,
        saveMarketingEmailError: null,
        contactDetailsChanged: false,
      };

    case RESET_PASSWORD_REQUEST:
      return { ...state, resetPasswordInProgress: true, resetPasswordError: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordInProgress: false };
    case RESET_PASSWORD_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, resetPasswordInProgress: false, resetPasswordError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = (user) => ({ type: SAVE_CONTACT_DETAILS_SUCCESS, payload : user});
export const saveEmailError = error => ({
  type: SAVE_EMAIL_ERROR,
  payload: error,
  error: true,
});
export const savePhoneNumberError = error => ({
  type: SAVE_PHONE_NUMBER_ERROR,
  payload: error,
  error: true,
});
export const saveMarketingEmailError = error => ({
  type: SAVE_MARKETING_EMAIL_ERROR,
  payload: error,
  error: true,
});
export const saveNewBookingSmsNotificationError = error => ({
  type: SAVE_NEW_BOOKING_SMS_NOTIFICATION_ERROR,
  payload: error,
  error: true,
});

export const saveNewBookingWhatsappNotificationError = error => ({
  type: SAVE_NEW_BOOKING_WHATSAPP_NOTIFICATION_ERROR,
  payload: error,
  error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

export const resetPasswordRequest = () => ({ type: RESET_PASSWORD_REQUEST });

export const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });

export const resetPasswordError = e => ({
  type: RESET_PASSWORD_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

/**
 * Make a phone number update request to the API and return the current user.
 */
const requestSavePhoneNumber = params => (dispatch, getState, sdk) => {
  const phoneNumber = params.phoneNumber;

  return sdk.currentUser
    .updateProfile(
      { protectedData: { phoneNumber } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(savePhoneNumberError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Make a email update request to the API and return the current user.
 */
const requestSaveEmail = params => (dispatch, getState, sdk) => {
  const { email, currentPassword } = params;

  return sdk.currentUser
    .changeEmail(
      { email, currentPassword },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveEmailError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Make a klaviyo email update request to the API
 */
const requestKlaviyoUpdateEmail = params => (dispatch, getState, sdk) => {
  const { email } = params;

  return sdk.currentUser
    .show()
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const sharetribeId = response.data.data.id.uuid
      const email = params.email

      var klaviyoResponse = await klaviyoGetProfile({externalId: sharetribeId})
      if (typeof klaviyoResponse.data.data[0] != 'undefined') {
        klaviyoUpdateProfile({id: klaviyoResponse.data.data[0].id, email: email})
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      throw e;
    });
};

/**
 * Make a klaviyo phone number update request to the API
 */
const requestKlaviyoUpdatePhoneNumber = params => (dispatch, getState, sdk) => {
  const { phoneNumber } = params;

  return sdk.currentUser
    .show()
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const sharetribeId = response.data.data.id.uuid
      const email = params.phoneNumber

      var klaviyoResponse = await klaviyoGetProfile({externalId: sharetribeId})
      if (typeof klaviyoResponse.data.data[0] != 'undefined') {
        klaviyoUpdateProfile({id: klaviyoResponse.data.data[0].id, phoneNumber: phoneNumber})
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      throw e;
    });
};
/**
 * Save the marketing email subscription option
 */
const requestMarketingEmail = params => async (dispatch, getState, sdk) => {
  const firstName = params.userProfile.firstName;
  const lastName = params.userProfile.lastName;
  const email = params.userProfile.email;
  const phoneNumber = params.userProfile.phoneNumber;
  const marketingEmailOption = params.marketingEmailOption;

  return sdk.currentUser
    .updateProfile(
      { protectedData: { marketingEmailOption } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const sharetribeId = response.data.data.id.uuid
      // Get profile ID from Klaviyo - needed to update the profile later
      klaviyoGetProfile({externalId: sharetribeId})
      .then(response => {
        var id = response.data.data[0].id
        if (typeof response.data.data[0] != 'undefined' ) {
        id = response.data.data[0].id
        }

        klaviyoUpdateProfile({
          id: id,
          marketingEmailOption: marketingEmailOption
        });
      })

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      return currentUser;
    })
    .catch(e => {
      dispatch(saveMarketingEmailError(storableError(e)));
      throw e;
    });
}


/**
 * Save the sms notification option
 */
const requestSaveNewBookingSmsNotification = params => (dispatch, getState, sdk) => {
  const newBookingSmsNotification = params.newBookingSmsNotification;
  return sdk.currentUser
    .updateProfile(
      { protectedData: { newBookingSmsNotification } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveNewBookingSmsNotificationError(storableError(e)));
      throw e;
    });
}

/**
 * Save the whatsapp notification option
 */
const requestSaveNewBookingWhatsappNotification = params => (dispatch, getState, sdk) => {
  const newBookingWhatsappNotification = params.newBookingWhatsappNotification;
  return sdk.currentUser
    .updateProfile(
      { protectedData: { newBookingWhatsappNotification } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveNewBookingWhatsappNotificationError(storableError(e)));
      throw e;
    });
}

/**
 * Save email and update the current user.
 */
const saveEmail = params => (dispatch, getState, sdk) => {
  const { email, currentPassword } = params

  const promises = [
    dispatch(requestSaveEmail({ email, currentPassword })),
    dispatch(requestKlaviyoUpdateEmail({ email }))
  ]

  return Promise.all(promises)
    .then(values => {
      // Array of two user objects is resolved
      // the first one is from the phone number update
      // the second one is from the marketing email update

      const saveEmailUser = values[0];
      const saveMarketingEmailUser = values[1];
      let currentUser;
      if (Object.keys(saveMarketingEmailUser).length !== 0) {

        // merge the public data from the user object returned
        // by the activeCampiagn operation
        const publicData = saveMarketingEmailUser.attributes.profile.publicData;
        const phoneNumberMergeSource = { attributes: { profile: { publicData } } };

        currentUser = merge(saveEmailUser, phoneNumberMergeSource);
      } else {
        currentUser = merge(saveEmailUser);
      }
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess(currentUser));
    })
    .catch(e => null);
};

/**
 * Save phone number and update the current user.
 */
const savePhoneNumber = params => (dispatch, getState, sdk) => {
  const { phoneNumber } = params;

  // order of promises: 1. phone number
  const promises = [
    dispatch(requestSavePhoneNumber({ phoneNumber })),
    dispatch(requestKlaviyoUpdatePhoneNumber({ phoneNumber }))
  ];

  return Promise.all(promises)
    .then(values => {
      // Array of user objects is resolved
      // the first one is from the phone number update

      const savePhoneNumberUser = values[0];

      let currentUser = savePhoneNumberUser;
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess(currentUser));

    })
    .catch(e => null);
};

/**
 * Save marketing email option and update the current user.
 */
const saveMarketingEmailOption = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestMarketingEmail(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess(user));
      })
      // error action dispatched in requestSavePhoneNumber
      .catch(e => null)
  );
}

/**
 * Save email and phone number and update the current user.
 */
const saveEmailAndPhoneNumber = params => (dispatch, getState, sdk) => {
  const { email, phoneNumber, currentPassword } = params;

  // order of promises: 1. email, 2. phone number
  const promises = [
    dispatch(requestSaveEmail({ email, currentPassword })),
    dispatch(requestKlaviyoUpdateEmail({ email })),
    dispatch(requestSavePhoneNumber({ phoneNumber })),
    dispatch(requestKlaviyoUpdatePhoneNumber({ phoneNumber }))
  ];

  return Promise.all(promises)
    .then(values => {
      // Array of two user objects is resolved
      // the first one is from the email update
      // the second one is from the phone number update

      const saveEmailUser = values[0];
      const savePhoneNumberUser = values[1];

      // merge the protected data from the user object returned
      // by the phone update operation
      const protectedData = savePhoneNumberUser.attributes.profile.protectedData;
      const phoneNumberMergeSource = { attributes: { profile: { protectedData } } };

      const currentUser = merge(saveEmailUser, phoneNumberMergeSource);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess(currentUser));
    })
    .catch(e => null);
};

/**
 * Save email and marketing email opt-in.
 */
const saveEmailAndMarketingEmailOption = params => (dispatch, getState, sdk) => {
  const { email, currentPassword } = params;

  // order of promises: 1. email, 2. marketing email opt-in
  const promises = [
    dispatch(requestSaveEmail({ email, currentPassword })),
    dispatch(requestMarketingEmail(params)),
  ];

  return Promise.all(promises)
    .then(values => {
      // Array of two user objects is resolved
      // the first one is from the email update
      // the second one is from the marketing email update

      const saveEmailUser = values[0];
      const saveMarketingEmailUser = values[1];

      // merge the public data from the user object returned
      // by the marketing email update operation
      const publicData = saveMarketingEmailUser.attributes.profile.publicData;
      const marketingEmailMergeSource = { attributes: { profile: { publicData } } };

      const currentUser = merge(saveEmailUser, marketingEmailMergeSource);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess(currentUser));
    })
    .catch(e => null);
}

/**
 * Save phone number and marketing email opt-in.
 */
const savePhoneNumberAndMarketingEmailOption = params => (dispatch, getState, sdk) => {
  const { phoneNumber } = params;

  // order of promises: 1. phone number, 2. marketing email opt-in
  const promises = [
    dispatch(requestSavePhoneNumber({ phoneNumber })),
    dispatch(requestMarketingEmail(params)),
  ];

  return Promise.all(promises)
    .then(values => {
      // Array of two user objects is resolved
      // the first one is from the phone number update
      // the second one is from the marketing email update

      const savePhoneNumberUser = values[0];
      const saveMarketingEmailUser = values[1];

      // merge the public data from the user object returned
      // by the marketing email operation
      const publicData = saveMarketingEmailUser.attributes.profile.publicData;
      const marketingEmailMergeSource = { attributes: { profile: { publicData } } };

      const currentUser = merge(savePhoneNumberUser, marketingEmailMergeSource);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess(currentUser));
    })
    .catch(e => null);
}

/**
  * Save email, phone number and marketing email opt-in.
  */
const saveEmailAndPhoneNumberAndMarketingEmailOption = params => (dispatch, getState, sdk) => {
  const { email, currentPassword, phoneNumber } = params;

  // order of promises: 1. phone number, 2. marketing email opt-in
  const promises = [
    dispatch(requestSaveEmail({ email, currentPassword })),
    dispatch(requestSavePhoneNumber({ phoneNumber })),
    dispatch(requestMarketingEmail(params)),
  ];

  return Promise.all(promises)
    .then(values => {
      // Array of three user objects is resolved
      // the first one is from the email update
      // the second one is from the phone number update
      // the third is the marketing email update

      const saveEmailUser = values[0];
      const savePhoneNumberUser = values[1];
      const saveMarketingEmail = values[2]

      // merge the protected data from the user object returned
      // by the phone update operation
      const protectedData = savePhoneNumberUser.attributes.profile.protectedData;
      const phoneNumberMergeSource = { attributes: { profile: { protectedData } } };
      const publicData = saveMarketingEmail.attributes.profile.publicData;
      const marketingEmailMergeSource = { attributes: { profile: { publicData } } }

      const currentUser = merge(saveEmailUser, phoneNumberMergeSource, marketingEmailMergeSource);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess(currentUser));
    })
    .catch(e => null);
}

/**
 * Save newBookingSmsNotification
 */
const saveNewBookingSmsNotification = params => (dispatch, getState, sdk) => {
  return (

    dispatch(requestSaveNewBookingSmsNotification(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess(user));
      })
      // error action dispatched in requestSaveNewBookingSmsNotification
      .catch(e => null)
  );
}

/**
 * Save newBookingWhatsappNotification
 */
const saveNewBookingWhatsappNotification = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveNewBookingWhatsappNotification(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess(user));
      })
      // error action dispatched in requestSaveNewBookingWhatsappNotification
      .catch(e => null)
  );
}

/**
 * Update contact details, actions depend on which data has changed
 */
export const saveContactDetails = params => (dispatch, getState, sdk) => {
  dispatch(saveContactDetailsRequest());

  const { email, currentEmail,
    phoneNumber, currentPhoneNumber,
    currentPassword,
    newBookingSmsNotification, currentNewBookingSmsNotification,
    newBookingWhatsappNotification, currentNewBookingWhatsappNotification,
    marketingEmailOption, currentMarketingEmailOption } = params;

  const emailChanged = email !== currentEmail;
  const phoneNumberChanged = phoneNumber !== currentPhoneNumber;
  const newBookingSmsNotificationChanged = newBookingSmsNotification !== currentNewBookingSmsNotification;
  const newBookingWhatsappNotificationChanged = newBookingWhatsappNotification !== currentNewBookingWhatsappNotification;
  const marketingEmailOptionChanged = marketingEmailOption !== currentMarketingEmailOption;

  if (emailChanged && phoneNumberChanged && marketingEmailOptionChanged) {
    return dispatch(saveEmailAndPhoneNumberAndMarketingEmailOption(params))
  } else if (emailChanged && marketingEmailOptionChanged) {
    return dispatch(saveEmailAndMarketingEmailOption(params))
  } else if (phoneNumberChanged && marketingEmailOptionChanged) {
    return dispatch(savePhoneNumberAndMarketingEmailOption(params))
  } else if (emailChanged && phoneNumberChanged) {
    return dispatch(saveEmailAndPhoneNumber(params));
  } else if (emailChanged) {
    return dispatch(saveEmail(params));
  } else if (phoneNumberChanged) {
    return dispatch(savePhoneNumber(params));
  } else if (marketingEmailOptionChanged) {
    return dispatch(saveMarketingEmailOption(params))
  } else if (newBookingSmsNotificationChanged) {
    return (dispatch(saveNewBookingSmsNotification(params)))
  } else if (newBookingWhatsappNotificationChanged) {
    return (dispatch(saveNewBookingWhatsappNotification(params)))
  }
};

export const resetPassword = email => (dispatch, getState, sdk) => {
  dispatch(resetPasswordRequest());
  return sdk.passwordReset
    .request({ email })
    .then(() => dispatch(resetPasswordSuccess()))
    .catch(e => dispatch(resetPasswordError(storableError(e))));
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};