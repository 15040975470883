import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';


// react-dates needs to be initialized before using any react-dates component
// https://github.com/airbnb/react-dates#initialize
// NOTE: Initializing it here will initialize it also for app.test.js
import 'react-dates/initialize';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Tap from "./tapfiliate";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { IntlProvider } from './util/reactIntl';
import configureStore from './store';
import routeConfiguration from './routeConfiguration';
import Routes from './Routes';
import config from './config';
import { LanguageContext } from './context/LanguageContext'

// Flex template application uses English translations as default.
// These messages will be loaded by default in SSR
import defaultMessages from './translations/en.json';

const isTestEnv = process.env.NODE_ENV === 'test';

const setupLocale = () => {
  if (isTestEnv) {
    // Use english as a default locale in tests
    // This affects app.test.js and app.node.test.js tests
    config.locale = 'en';
    return;
  }

  // Set the Moment locale globally
  // See: http://momentjs.com/docs/#/i18n/changing-locale/
  moment.locale(config.locale);
};

if (typeof window !== "undefined") {
  const REACT_APP_TAPFILIATE_CLIENT_ID = process.env.REACT_APP_TAPFILIATE_CLIENT_ID;
  const clientId = REACT_APP_TAPFILIATE_CLIENT_ID.toString();  
  Tap.init(clientId, { integration: "stripe" });
}

export const ClientApp = props => {
  const { store } = props;
  const { language, messages } = useContext(LanguageContext);
  setupLocale();
  return (
    <IntlProvider locale={language} messages={messages} textComponent="span">
      <Provider store={store}>
        <HelmetProvider>
          <BrowserRouter>
            <Routes routes={routeConfiguration()} />
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </IntlProvider>
  );
};

const { any, string } = PropTypes;

ClientApp.propTypes = { store: any.isRequired };

export const ServerApp = props => {
  const { url, context, helmetContext, store } = props;
  setupLocale();
  HelmetProvider.canUseDOM = false;
  return (
    <IntlProvider locale={config.locale} messages={defaultMessages} textComponent="span">
      <Provider store={store}>
        <HelmetProvider context={helmetContext}>
          <StaticRouter location={url} context={context}>
            <Routes routes={routeConfiguration()} />
          </StaticRouter>
        </HelmetProvider>
      </Provider>
    </IntlProvider>
  );
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (url, serverContext, preloadedState, collectChunks) => {
  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.
  const store = configureStore(preloadedState);

  const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp url={url} context={serverContext} helmetContext={helmetContext} store={store} />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};
