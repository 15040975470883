// ================ Action types ================ //

export const URL_REQUESTED = 'app/METADATA/URL_REQUESTED';

// ================ Reducer ================ //

const initialState = {
  serverOrigin: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case URL_REQUESTED: {
      return {
        ...state,
        serverOrigin: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const UrlRequested = (url) => ({
  type: URL_REQUESTED,
  payload: url ,
});

// ================ Selectors ================ //

export const getTourlyServerOrigin = (state) => {
  const { serverOrigin } = state.METADATA;
  return serverOrigin;
};

