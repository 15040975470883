import { LOCATION_CHANGED } from '../ducks/Routing.duck';
import { USER_LOGIN, USER_LOGOUT } from '../ducks/Auth.duck';
import { SAVE_CONTACT_DETAILS_SUCCESS } from '../containers/ContactDetailsPage/ContactDetailsPage.duck';
import { INITIATE_ORDER_SUCCESS, SET_INITIAL_VALUES } from '../containers/CheckoutPage/CheckoutPage.duck'
import { SIGNUP_SUCCESS, CHECKOUT_PAGE_SIGNUP_SUCCESS } from '../ducks/Auth.duck';

// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(url): called when the URL is changed
export const createMiddleware = handlers => ({getState}) => next => action => {
  const { type, payload, orderParams } = action;

  // Pageview events
  if (type === LOCATION_CHANGED) {
    const { canonicalUrl } = payload;
    handlers.forEach(handler => {
      if (typeof handler.trackPageView == 'function') { 
        handler.trackPageView(canonicalUrl);
      }
    });
  }

  // User login events
  if (type === USER_LOGIN) {
    const userid = payload;
    handlers.forEach(handler => {
      if (typeof handler.trackUserLogin == 'function') {
        handler.trackUserLogin(userid);
      }
    });
  }

  // Allows for setting user properties
  if (type === SAVE_CONTACT_DETAILS_SUCCESS) {
    const currentUser = payload;
    // Only call handlers if currentuser exists and is defined (not null, undefinde, {}, etc.)
    // see https://stackoverflow.com/questions/5515310/is-there-a-standard-function-to-check-for-null-undefined-or-blank-variables-in
    if (typeof currentUser !== 'undefined' && currentUser)
      handlers.forEach(handler => {
        if (typeof handler.trackUserProperties == 'function') {
          handler.trackUserProperties(currentUser);
        }
      });
  }

  // User logout events
  if (type === USER_LOGOUT) {
    handlers.forEach(handler => {
      if (typeof handler.trackUserLogout == 'function') {
        handler.trackUserLogout();
      }
    });
  }

  // Check-out events
  if (type === INITIATE_ORDER_SUCCESS) {
    const order = payload;
    handlers.forEach(handler => {
      if (typeof handler.trackCheckout == 'function') { 
        handler.trackCheckout(order, orderParams);
      }
    });
  }

  // Add-to-cart events
  if (type === SET_INITIAL_VALUES) {
    const order = payload;
    handlers.forEach(handler => {
      if (typeof handler.trackAddToCart == 'function') { 
        handler.trackAddToCart(order);
      }
    });
  }

  // Sign-up events
  if (type == SIGNUP_SUCCESS || type == CHECKOUT_PAGE_SIGNUP_SUCCESS) {
    const userData = payload;
    handlers.forEach(handler => {
      if (typeof handler.trackSignupRequest == 'function') {
        handler.trackSignupRequest(userData);
      }
    });
  }

  next(action);
};
