// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};
// Register user as an affiliate on Tapfiliate.
//
// This will use the backend to register a new user in Tapfiliate.
//
// See `server/api/register-affiliate.js` to see what data should
// be sent in the body.
export const registerAffiliate = body => {
  return post('/api/register-affiliate', body);
};

// Add affiliate to affiliate program on Tapfiliate.
//
// This will use the backend to assign an affiliate to our affiliate program.
//
// See `server/api/add-affiliate-to-program.js` to see what data should
// be sent in the body.
export const addAffiliateToProgram = body => {
  return post('/api/add-affiliate-to-program', body);
};

// Add affiliate to a tier on Tapfiliate.
//
// This will use the backend to assign an affiliate to a tier.
//
// See `server/api/add-affiliate-to-tier.js` to see what data should
// be sent in the body.
export const addAffiliateToTier = body => {
  return post('/api/add-affiliate-to-tier', body);
};

// Get affiliate balance from tapfiliate.
//
// This will use the backend to retrieve an affiliate's current balance from tapfiliate.
//
// See `server/api/get-affiliate-balance.js` to see what data should
// be sent in the body.
export const getAffiliateBalance = body => {
  return post('/api/get-affiliate-balance', body);
};

// Get affiliate conversions for a timeframe.
//
// This will use the backend to retrieve an affiliate's approved conversions for a given timeframe.
//
// @apiParam (Request body) {String} affiliateId The id for the affiliate (string)
// @apiParam (Request body) {String} dateFrom The date since when we want conversions to be retrieved from (format "yyyy-mm-dd")
// @apiParam (Request body) {String} dateTo The date until when we want conversions to be retrieved from (format "yyyy-mm-dd")
// 
// @apiSuccess (Success 201) {JSON} See example response for 'data' in https://tapfiliate.com/docs/rest/#conversions-conversions-collection-get
//
// See `server/api/get-affiliate-conversions.js` for more context.
export const getAffiliateConversions = body => {
  return post('/api/get-affiliate-conversions', body);
};

// Get affiliate data.
//
// This will use the backend to retrieve an affiliate's data.
//
// @apiParam (Request body) {String} affiliateId The id for the affiliate (string)
// 
// @apiSuccess (Success 201) {JSON} See example response for 'data' in https://tapfiliate.com/docs/rest/#affiliates-affiliate-get
//
// See `server/api/get-affiliate-conversions.js` for more context.
export const getAffiliate = body => {
  return post('/api/get-affiliate', body);
};

// Get affiliate report.
//
// This will use the backend to retrieve an affiliate report for a given timeframe.
//
// @apiParam (Request body) {String} affiliateId The id for the affiliate (string)
// @apiParam (Request body) {String} dateFrom The date since when we want the report to be retrieved from (format "yyyy-mm-dd")
// @apiParam (Request body) {String} dateTo The date until when we want the report to be retrieved from (format "yyyy-mm-dd")
// 
// @apiSuccess (Success 201) {JSON} This is a private API, so here's an example output:
//
// [
//  {
//    "clicks": 5,
//    "customers": 2,
//    "approved_conversions": 1,
//    "approved_conversion_amount": 45,
//    "approved_commission_amount": 2.25,
//    "approved_mlm_commission_amount": 0,
//    "title": "Test SA1"
//   }
// ]
//
// See `server/api/get-affiliate-report.js` for more context.
export const getAffiliateReport = body => {
  return post('/api/get-affiliate-report', body);
};

// Fetch the Stripe payment intent object from the local API endpoint.
//
// See `server/api/payment-intent.js` to see what data should
// be sent in the body.
export const stripePaymentIntent = body => {
  return post('/api/stripe-payment-intent', body);
};

// Update Stripe payout schedule from the local API endpoint.
//
// See `server/api/stripe-configure-payout.js` to see what data should
// be sent in the body.
export const stripeConfigurePayout = body => {
  return post('/api/stripe-configure-payout', body);
};

// Messaging gateway
export const sendSms = body => {
  return post('/api/send-sms', body);
}

export const sendWhatsapp = body => {
  return post('/api/send-whatsapp', body);
}

// Slack notifications
export const postToSlackAlerts = body => {
  return post('/api/post-to-slack-alerts', body);
}
export const postToSlackSales = body => {
  return post('/api/post-to-slack-sales', body);
}

// Klaviyo
export const klaviyoCreateProfile = body => {
  return post('/api/klaviyo-create-profile', body);
}
export const klaviyoUpdateProfile = body => {
  return post('/api/klaviyo-update-profile', body);
}
export const klaviyoGetProfile = body => {
  return post('/api/klaviyo-get-profile', body);
}
export const klaviyoTrackPageView = body => {
  return post('/api/klaviyo-track-page-view', body);
}
export const klaviyoTrackNewOrder = body => {
  return post('/api/klaviyo-track-new-order', body);
}
export const klaviyoTrackAddToCart = body => {
  return post('/api/klaviyo-track-add-to-cart', body);
}