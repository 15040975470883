import Tap from  "../../tapfiliate";
import pick from 'lodash/pick';
import config from '../../config';
import { initiatePrivileged, transitionPrivileged, stripePaymentIntent, sendSms, sendWhatsapp, postToSlackSales } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';

import recombee from 'recombee-js-api-client/dist/recombee-api-client';
import { getCookie, uuidPattern } from '../../util/data';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = (order,orderParams) => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
  orderParams
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  // added hasAffiliate property to the bookingData object
  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    bookingQuantityAdult: orderParams.bookingQuantityAdult,
    bookingQuantityChild: orderParams.bookingQuantityChild,
    bookingQuantityInfant: orderParams.bookingQuantityInfant,
    hasAffiliate: orderParams.hasAffiliate,
    seasonalTourCommission: orderParams.seasonalTourCommission,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order,orderParams));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams, { expand: true })
    .then(response => {
      const order = response.data.data;
      let providerPhoneNumber = order.attributes.protectedData.providerPhoneNumber
      let notifyProviderSMS = order.attributes.protectedData.newBookingSmsNotification;
      let notifyProviderWhatsapp = order.attributes.protectedData.newBookingWhatsappNotification;

      stripePaymentIntent({ paymentIntentId: order.attributes.protectedData.stripePaymentIntents.default.stripePaymentIntentId })
      .then(response => {
        const paymentIntentData = response.data;
        if (typeof window !== "undefined" && window.localStorage.getItem('tap_vid') !== null) {
          config.dev && console.log("Calling tap conversion");
          Tap.conversion(paymentIntentData.charges.data[0].id, paymentIntentData.charges.data[0].amount/100, {customer_id: paymentIntentData.customer})
        }
        
        // Notify provider of the new reservation
        const newBookingSmsNotificationMessage = 'Dear Partner,\nYou have a new booking request on Tourly,\nPlease visit https://tourly.pt/inbox/sales to accept.';
        const newBookingWhatsappNotificationMessage = 'Dear Partner,\nYou have a new booking request on Tourly,\nPlease visit your inbox to accept.'
        if (typeof notifyProviderSMS !== 'undefined' && notifyProviderSMS) {
          sendSms({ phoneNumber: providerPhoneNumber, message: newBookingSmsNotificationMessage})
        }
        if (typeof notifyProviderWhatsapp !== 'undefined' && notifyProviderWhatsapp) {
          sendWhatsapp({ phoneNumber: providerPhoneNumber, message: newBookingWhatsappNotificationMessage})
        }

        // Post sale notification on Slack
        if (window.location.hostname != 'localhost' && window.location.hostname != 'test.tourly.pt') {
          let bookingStart = getState().CheckoutPage.bookingData.bookingStartDate.date
          let providerName = getState().CheckoutPage.listing.author.attributes.profile.displayName
          let customerName = getState().user.currentUser.attributes.profile.displayName
          let customerEmail = getState().user.currentUser.attributes.email
          let hasAffiliate = typeof getState().CheckoutPage.bookingData.hasAffiliate != 'undefined' && getState().CheckoutPage.bookingData.hasAffiliate ? 'Yes' : 'No' 
          const newBookingSlackNotificationMessage = 'New booking request for ' + paymentIntentData.description + ' by ' + providerName + ' (' + providerPhoneNumber + ')\nTransaction ID: ' + paymentIntentData.metadata["sharetribe-transaction-id"] + '\nCustomer: ' + customerName + ' (' + customerEmail + ')\nBooking date: ' + bookingStart + '\nPrice: ' + paymentIntentData.amount/100 + '€ Affiliate sale: ' + hasAffiliate
          postToSlackSales({ message: newBookingSlackNotificationMessage })
        }

        if (typeof window !== 'undefined') {
          if (typeof window.tp === 'function') {
            // Trustpilot Review Invitation
            const email =  getState().user.currentUser.attributes.email
            const firstName = getState().user.currentUser.attributes.profile.firstName
            const lastName = getState().user.currentUser.attributes.profile.lastName
            const transactionId = getState().CheckoutPage.transaction.id.uuid

            const trustpilot_invitation = {
              recipientEmail: email,
              recipientName: firstName + " " + lastName,
              referenceId: transactionId,
              source: 'InvitationScript',
            };
            config.dev && console.log('Sending trustpilot invitation.');
            window.tp('createInvitation', trustpilot_invitation);
          }

          // Send Checkout to Recombee
          if (process.env.REACT_APP_RECOMBEE_DB && process.env.REACT_APP_RECOMBEE_PUBLIC_TOKEN) {
            
            // Initialize client with the database and public token
            var client = new recombee.ApiClient(process.env.REACT_APP_RECOMBEE_DB, process.env.REACT_APP_RECOMBEE_PUBLIC_TOKEN, {region: 'eu-west'});
            
            // Get current user UUID
            const currentUserUuid = getCookie('st_uuid')

            // Get current timestamp
            const isoTimestamp = new Date().toISOString()

            // Get current listingId
            const listingId = getState().ListingPage.id.uuid

            // Get listing price
            const price = paymentIntentData.charges.data[0].amount/100;

            // At this point, user is always authenticated
            if (uuidPattern.test(listingId) && uuidPattern.test(currentUserUuid) && price) {
              client.send(new recombee.AddPurchase(currentUserUuid, listingId, {
              'timestamp': isoTimestamp,
              'price': price
              }))
            }
          }

          // Facebook Pixel Conversion Tracking
          if (typeof window.fbq === 'function') {
            config.dev && console.log('Registering purchase on Facebook Pixel.');
            window.fbq('track', 'Purchase', {currency: "EUR", value: paymentIntentData.charges.data[0].amount/100});
          }
          // Google Analytics Conversion Tracking for Google Ads & Google Analytics
          if (typeof window.gtag === 'function') {
            if (process.env.REACT_APP_GOOGLE_ADS_ID) {
              config.dev &&
                console.log(
                  'Registered conversion on GAds with ID ' +
                    process.env.REACT_APP_GOOGLE_ADS_ID +
                    '/G3zMCNaE_4oDEPzrhpIo'
                );
              window.gtag('event', 'conversion', {
                send_to: process.env.REACT_APP_GOOGLE_ADS_ID + '/G3zMCNaE_4oDEPzrhpIo',
                value: paymentIntentData.charges.data[0].amount / 100,
                currency: 'EUR',
              });
            }
            window.gtag('event', 'conversion', {
              send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
              value: paymentIntentData.charges.data[0].amount / 100,
              currency: 'EUR',
            });
          }
        }
      })
      .catch(e => {
        config.dev && console.log("Failed some step(s) to register conversion");
        console.error(e);
      });
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  // added hasAffiliate property to the bookingData object
  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    bookingQuantityAdult: orderParams.bookingQuantityAdult,
    bookingQuantityChild: orderParams.bookingQuantityChild,
    bookingQuantityInfant: orderParams.bookingQuantityInfant,
    hasAffiliate: orderParams.hasAffiliate,
    seasonalTourCommission: orderParams.seasonalTourCommission
  };

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
