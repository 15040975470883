import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getAffiliate, getAffiliateConversions, getAffiliateReport } from '../../util/api';
import { storableError } from '../../util/errors';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/AffiliateDashboardPage/SET_INITIAL_VALUES';

export const GET_CONVERSION_REQUEST = 'app/AffiliateDashboardPage/GET_CONVERSION_REQUEST';
export const GET_CONVERSION_SUCCESS = 'app/AffiliateDashboardPage/GET_CONVERSION_SUCCESS';
export const GET_CONVERSION_ERROR = 'app/AffiliateDashboardPage/GET_CONVERSION_ERROR';
export const GET_REMAINING_SALES_REQUEST = 'app/AffiliateDashboardPage/GET_REMAINING_SALES_REQUEST';
export const GET_REMAINING_SALES_SUCCESS = 'app/AffiliateDashboardPage/GET_REMAINING_SALES_SUCCESS';
export const GET_REMAINING_SALES_ERROR = 'app/AffiliateDashboardPage/GET_REMAINING_SALES_ERROR';
export const GET_REPORT_REQUEST = 'app/AffiliateDashboardPage/GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'app/AffiliateDashboardPage/GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'app/AffiliateDashboardPage/GET_REPORT_ERROR';
export const GET_TIER_REQUEST = 'app/AffiliateDashboardPage/GET_TIER_REQUEST';
export const GET_TIER_SUCCESS = 'app/AffiliateDashboardPage/GET_TIER_SUCCESS';
export const GET_TIER_ERROR = 'app/AffiliateDashboardPage/GET_TIER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fromReturnURL: false,
  clicks: '',
  conversion: '',
  remainingSales: '',
  tier: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    case GET_CONVERSION_REQUEST:
      return { ...state, getConversionError: null, userToken: payload.userToken };
    case GET_CONVERSION_SUCCESS:
      return { ...state, conversion: payload };
    case GET_CONVERSION_ERROR:
      return { ...state, getConversionError: payload };
    case GET_REMAINING_SALES_REQUEST:
      return { ...state, getRemainingSalesError: null, userToken: payload.userToken };
    case GET_REMAINING_SALES_SUCCESS:
      return { ...state, remainingSales: payload };
    case GET_REMAINING_SALES_ERROR:
      return { ...state, getRemainingSalesError: payload };
    case GET_REPORT_REQUEST:
      return { ...state, getReportError: null, userToken: payload.userToken };
    case GET_REPORT_SUCCESS:
      return { ...state, clicks: payload };
    case GET_REPORT_ERROR:
      return { ...state, getReportError: payload };
    case GET_TIER_REQUEST:
      return { ...state, getError: null, userToken: payload.userToken };
    case GET_TIER_SUCCESS:
      return { ...state, tier: payload };
    case GET_TIER_ERROR:
      return { ...state, getTierError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const getConversionRequest = userToken => ({
  type: GET_CONVERSION_REQUEST,
  payload: { userToken },
});

export const getConversionSuccess = (total, commission, bookings) => ({
  type: GET_CONVERSION_SUCCESS,
  payload: { total, commission, bookings },
});

export const getConversionError = () => ({
  type: GET_CONVERSION_ERROR,
});

export const getRemainingSalesRequest = userToken => ({
  type: GET_REMAINING_SALES_REQUEST,
  payload: { userToken },
});

export const getRemainingSalesSuccess = total => ({
  type: GET_REMAINING_SALES_SUCCESS,
  payload: { total },
});

export const getRemainingSalesError = () => ({
  type: GET_REMAINING_SALES_ERROR,
});

export const getReportRequest = userToken => ({
  type: GET_REPORT_REQUEST,
  payload: { userToken },
});

export const getReportSuccess = clicks => ({
  type: GET_REPORT_SUCCESS,
  payload: { clicks },
});

export const getReportError = () => ({
  type: GET_REPORT_ERROR,
});

export const getTierRequest = userToken => ({
  type: GET_TIER_REQUEST,
  payload: { userToken },
});

export const getTierSuccess = tier => ({
  type: GET_TIER_SUCCESS,
  payload: { tier },
});

export const getTierError = () => ({
  type: GET_TIER_ERROR,
});

// ================ Thunks ================ //

export const getMonthTotal = userToken => (dispatch, getState, sdk) => {
  dispatch(getConversionRequest(userToken));
  const firstDay = moment()
    .startOf('month')
    .format('DD-MM-YYYY');
  const lastDay = moment()
    .endOf('month')
    .format('DD-MM-YYYY');
  getAffiliateConversions({ affiliateId: userToken, dateFrom: firstDay, dateTo: lastDay })
    .then(response => {
      const total = response.data
        .map(conversion => conversion.amount)
        .reduce((prev, curr) => prev + curr, 0);

      const commission = response.data
        .map(conversion => conversion.commissions[0].amount)
        .reduce((prev, curr) => prev + curr, 0);

      const bookings = response.data.length;

      dispatch(getConversionSuccess(total, commission, bookings));
      return { total, commission, bookings };
    })
    .catch(e => {
      dispatch(getConversionError(storableError(e)));
    });
};

export const getRemainingSales = userToken => (dispatch, getState, sdk) => {
  dispatch(getRemainingSalesRequest(userToken));
  const firstDay = moment()
    .subtract(3, 'M')
    .format('DD-MM-YYYY');
  const lastDay = moment().format('DD-MM-YYYY');
  getAffiliateConversions({ affiliateId: userToken, dateFrom: firstDay, dateTo: lastDay })
    .then(response => {
      const total = response.data
        .map(conversion => conversion.amount)
        .reduce((prev, curr) => prev + curr, 0);

      dispatch(getRemainingSalesSuccess(total));
      return total;
    })
    .catch(e => {
      dispatch(getRemainingSalesError(storableError(e)));
    });
};

export const getClicks = userToken => (dispatch, getState, sdk) => {
  dispatch(getReportRequest(userToken));
  const firstDay = moment()
    .startOf('month')
    .format('DD-MM-YYYY');
  const lastDay = moment()
    .endOf('month')
    .format('DD-MM-YYYY');
  getAffiliateReport({ affiliateId: userToken, dateFrom: firstDay, dateTo: lastDay })
    .then(response => {
      const clicks = typeof response.data[0] !== 'undefined' ? response.data[0]['clicks'] : '0';
      dispatch(getReportSuccess(clicks));
      return clicks;
    })
    .catch(e => {
      dispatch(getReportError(storableError(e)));
    });
};

export const getTier = userToken => (dispatch, getState, sdk) => {
  dispatch(getTierRequest(userToken));
  getAffiliate({ affiliateId: userToken })
    .then(response => {
      const affiliate_group_id = response.data['affiliate_group_id'];
      dispatch(getTierSuccess(affiliate_group_id));
      return affiliate_group_id;
    })
    .catch(e => {
      dispatch(getTierError(storableError(e)));
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;

    dispatch(getMonthTotal(currentUser.attributes.profile.privateData.id));
    dispatch(getRemainingSales(currentUser.attributes.profile.privateData.id));
    dispatch(getClicks(currentUser.attributes.profile.privateData.id));
    dispatch(getTier(currentUser.attributes.profile.privateData.id));
  });
};
