import React, { createContext, useState, useEffect } from "react";
import getTranslatedMessages from "../translations/translationMessages";
export const LanguageContext = createContext({language:'en', messages: getTranslatedMessages('en')});
import difference from 'lodash/difference';

function getInitialState() {
  if (typeof window === undefined ) return 'en'
  if (typeof window !== undefined ){
    const langData = window.localStorage.getItem("lang");
    return langData ? JSON.parse(langData) : 'en'
  }
}

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(getInitialState);
  const [messages, setMessages] = useState(getTranslatedMessages('en'));
  const defaultMessages = getTranslatedMessages('en').then( (englishMessages) => {
    return englishMessages;
  })
  useEffect((prevState) => {
    if (prevState !== language) {
      if (typeof window !== 'undefined'){
        window.localStorage.setItem('lang', JSON.stringify(language));
        getTranslatedMessages(language).then((translations) => {
          const updateTranslations = addMissingTranslations(defaultMessages, translations);
          setMessages(updateTranslations);
        });
      }
    }
  }, [language])

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { language: language, messages: messages, setLanguage };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}