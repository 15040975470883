import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-funchal',
    predictionPlace: {
      address: 'Funchal, Madeira, Portugal',
      bounds: new LatLngBounds(
        new LatLng(32.7855768, -16.86636538),
        new LatLng(32.60701485, -17.01852882)
      ),
    },
  },
  {
    id: 'default-santana',
    predictionPlace: {
      address: 'Santana, Madeira, Portugal',
      bounds: new LatLngBounds(
        new LatLng(32.830518, -16.854509),
        new LatLng(32.763222, -16.921095)
      ),
    },
  },
  {
    id: 'default-porto-moniz',
    predictionPlace: {
      address: 'Porto Moniz, Madeira, Portugal',
      bounds: new LatLngBounds(new LatLng(32.880829, -17.153836), new LatLng(32.80455, -17.210272)),
    },
  },
  {
    id: 'default-lisbon',
    predictionPlace: {
      address: 'Lisbon, Lisboa, Portugal',
      bounds: new LatLngBounds(new LatLng(38.79585375,-9.090571492), new LatLng(38.691399333,-9.229835564)),
    },
  },
];
export default defaultLocations;
