/**
 * Independent components
 * These components do not include any other components
 *
 * This order mitigates problems that might arise when trying to import components
 * that have circular dependencies to other components.
 * Note: import-order also affects to the generated CSS bundle file.
 *
 * Read more:
 * https://medium.com/visual-development/how-to-fix-nasty-circular-dependency-issues-once-and-for-all-in-javascript-typescript-a04c987cf0de
 */
import loadable from '@loadable/component'; // needed to lazy load components

// Banner
export const ChristmasBanner = loadable(() => import(/* webpackChunkName: "ChristmasBanner" */ './ChristmasBanner/ChristmasBanner'));
export const ChristmasBannerMobile = loadable(() => import(/* webpackChunkName: "ChristmasBanner" */ './ChristmasBannerMobile/ChristmasBannerMobile'));
export const BookNowBanner = loadable(() => import(/* webpackChunkName: "BookNowBanner" */ './BookNowBanner/BookNowBanner'));

// Icons
// Others Icons
export const IconAdd = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconAdd/IconAdd'));
export const IconArrowHead = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconArrowHead/IconArrowHead'));
export const IconBannedUser = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconBannedUser/IconBannedUser'));
export const IconCard = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconCard/IconCard'));
export const IconCheckmark = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconCheckmark/IconCheckmark'));
export const IconClose = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconClose/IconClose'));
export const IconCrafts = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconCrafts/IconCrafts'));
export const IconEdit = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconEdit/IconEdit'));
export const IconEmailAttention = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconEmailAttention/IconEmailAttention'));
export const IconEmailSent = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconEmailSent/IconEmailSent'));
export const IconEmailSuccess = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconEmailSuccess/IconEmailSuccess'));
export const IconEnquiry = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconEnquiry/IconEnquiry'));
export const IconFitness = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconFitness/IconFitness'));
export const IconKeys = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconKeys/IconKeys'));
export const IconKeysSuccess = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconKeysSuccess/IconKeysSuccess'));
export const IconRadical = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconRadical/IconRadical'));
export const IconReviewStar = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconReviewStar/IconReviewStar'));
export const IconReviewUser = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconReviewUser/IconReviewUser'));
export const IconSearch = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconSearch/IconSearch'));
export const IconSpinner = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconSpinner/IconSpinner'));
export const IconSuccess = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconSuccess/IconSuccess'));
export const IconTransfers = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconTransfers/IconTransfers'));
export const IconShield = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconShield/IconShield'));
export const IconChat = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconChat/IconChat'));
export const IconChatList = loadable(() => import(/* webpackChunkName: "OtherIcons" */ './Icons/IconChatList/IconChatList'));

// Core Icons - loaded on Search and Home pages
export const IconAccessibility = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconAccessibility/IconAccessibility'));
export const IconGastronomy = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconGastronomy/IconGastronomy'));
export const IconHiking = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconHiking/IconHiking'));
export const IconMuseum = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconMuseum/IconMuseum'));
export const IconNature = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconNature/IconNature'));
export const IconRoad = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconRoad/IconRoad'));
export const IconSeasonal = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconSeasonal/IconSeasonal'));
export const IconSocialMediaFacebook = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconSocialMediaFacebook/IconSocialMediaFacebook'));
export const IconSocialMediaInstagram = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconSocialMediaInstagram/IconSocialMediaInstagram'));
export const IconSocialMediaLinkedIn = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconSocialMediaLinkedIn/IconSocialMediaLinkedIn'));
export const IconSocialMediaTwitter = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconSocialMediaTwitter/IconSocialMediaTwitter'));
export const IconWater = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconWater/IconWater'));
export const IconFreeCancellation = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconFreeCancellation/IconFreeCancellation'));
export const IconClock = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconClock/IconClock'));
export const IconBus = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconBus/IconBus'));
export const IconEmptyStar = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconEmptyStar/IconEmptyStar'));
export const IconFullStar = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconFullStar/IconFullStar'));
export const IconHalfStar = loadable(() => import(/* webpackChunkName: "CoreIcons" */ './Icons/IconHalfStar/IconHalfStar'));

// Other independent components
export const AffiliateCard = loadable(() => import(/* webpackChunkName: "AffiliateDashboardPage" */ './AffiliateCard/AffiliateCard'));
export { default as ExternalLink } from './ExternalLink/ExternalLink';
export { default as ExpandingTextarea } from './ExpandingTextarea/ExpandingTextarea';
export { default as Form } from './Form/Form';
export { default as LimitedAccessBanner } from './LimitedAccessBanner/LimitedAccessBanner';
export { default as Logo } from './Logo/Logo';
export { default as NamedLink } from './NamedLink/NamedLink';
export { default as NamedRedirect } from './NamedRedirect/NamedRedirect';
export const NotificationBadge = loadable(() => import(/* webpackChunkName: "InboxPage" */ './NotificationBadge/NotificationBadge'));
export { default as OutsideClickHandler } from './OutsideClickHandler/OutsideClickHandler';
export { default as Promised } from './Promised/Promised';
export const PropertyGroup = loadable(() => import(/* webpackChunkName: "ListingPage" */ './PropertyGroup/PropertyGroup'));
export { default as RangeSlider } from './RangeSlider/RangeSlider';
export { default as ResponsiveImage } from './ResponsiveImage/ResponsiveImage';
export { default as TimeRange } from './TimeRange/TimeRange';
export const UserDisplayName = loadable(() => import(/* webpackChunkName: "InboxPage" */ './UserDisplayName/UserDisplayName'));
export { default as ValidationError } from './ValidationError/ValidationError';
export { default as LangSelector } from './LangSelecter/LangSelector';

/**
 * Composite components
 * These components include other components
 */

//////////////////////////////////////////////////////////
// First components that include only atomic components //
//////////////////////////////////////////////////////////

export { default as Button, PrimaryButton, SecondaryButton, InlineTextButton, DangerButton } from './Button/Button';
export const SocialLoginButton = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './Button/Button'));
export { default as CookieConsent } from './CookieConsent/CookieConsent';
export const ImageCarousel = loadable(() => import(/* webpackChunkName: "ListingPage" */ './ImageCarousel/ImageCarousel'));
export { default as ImageFromFile } from './ImageFromFile/ImageFromFile';
export { default as ListingLink } from './ListingLink/ListingLink';
export { default as PaginationLinks } from './PaginationLinks/PaginationLinks';
export { default as ReviewRating } from './ReviewRating/ReviewRating';

// Layout helpers
export { default as LayoutWrapperFooter } from './LayoutWrapperFooter/LayoutWrapperFooter';
export { default as LayoutWrapperMain } from './LayoutWrapperMain/LayoutWrapperMain';
export { default as LayoutWrapperSideNav } from './LayoutWrapperSideNav/LayoutWrapperSideNav';
export { default as LayoutWrapperTopbar } from './LayoutWrapperTopbar/LayoutWrapperTopbar';
export { default as LayoutSideNavigation } from './LayoutSideNavigation/LayoutSideNavigation';
export { default as LayoutSingleColumn } from './LayoutSingleColumn/LayoutSingleColumn';

// Menu
export { default as MenuItem } from './MenuItem/MenuItem';
export { default as MenuContent } from './MenuContent/MenuContent';
export { default as MenuLabel } from './MenuLabel/MenuLabel';
export { default as Menu } from './Menu/Menu';

// Modal
export { default as Modal } from './Modal/Modal';
export { default as ModalInMobile } from './ModalInMobile/ModalInMobile';

// Fields (for Final Form)
export { default as FieldBirthdayInput } from './FieldBirthdayInput/FieldBirthdayInput';
export { default as FieldCheckbox } from './FieldCheckbox/FieldCheckbox';
export { default as FieldCurrencyInput } from './FieldCurrencyInput/FieldCurrencyInput';
export { default as FieldDateInput } from './FieldDateInput/FieldDateInput';
export { default as FieldDateRangeController } from './FieldDateRangeController/FieldDateRangeController';
export { default as FieldDateRangeInput } from './FieldDateRangeInput/FieldDateRangeInput';
export { default as FieldRadioButton } from './FieldRadioButton/FieldRadioButton';
export { default as FieldReviewRating } from './FieldReviewRating/FieldReviewRating';
export { default as FieldSelect } from './FieldSelect/FieldSelect';
export { default as FieldQuantity } from './FieldQuantity/FieldQuantity';
export { default as FieldTextInput } from './FieldTextInput/FieldTextInput';
export { default as FieldTextInputWithCounter } from './FieldTextInputWithCounter/FieldTextInputWithCounter';
// Fields that use other Fields
export { default as FieldTimeZoneSelect } from './FieldTimeZoneSelect/FieldTimeZoneSelect';
export { default as FieldBoolean } from './FieldBoolean/FieldBoolean';
export { default as FieldCheckboxGroup } from './FieldCheckboxGroup/FieldCheckboxGroup';
export { default as FieldPhoneNumberInput } from './FieldPhoneNumberInput/FieldPhoneNumberInput';
export { default as FieldPhoneNumber } from './FieldPhoneNumber/FieldPhoneNumber';
// Fields and inputs using old naming pattern
export { default as LocationAutocompleteInput, LocationAutocompleteInputField } from './LocationAutocompleteInput/LocationAutocompleteInput';
export { default as StripeBankAccountTokenInputField } from './StripeBankAccountTokenInputField/StripeBankAccountTokenInputField';

// Tab navigation
export { default as TabNav } from './TabNav/TabNav';
export { LinkTabNavHorizontal, ButtonTabNavHorizontal } from './TabNavHorizontal/TabNavHorizontal';
export { default as Tabs } from './Tabs/Tabs';
export { default as UserNav } from './UserNav/UserNav';

///////////////////////////////////////////////
// These components include other components //
///////////////////////////////////////////////

export const AddListingCard = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './AddListingCard/AddListingCard'));
export { default as ActivityFeed } from './ActivityFeed/ActivityFeed';
export { default as AddImages } from './AddImages/AddImages';
export { default as Avatar, AvatarMedium, AvatarLarge } from './Avatar/Avatar';

export const BookingBreakdown = loadable(() => import(/* webpackChunkName: "CheckoutPage.Components" */ './BookingBreakdown/BookingBreakdown'));
export const BookingDateRangeFilter = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './BookingDateRangeFilter/BookingDateRangeFilter'));
export const BookingDateRangeLengthFilter = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './BookingDateRangeLengthFilter/BookingDateRangeLengthFilter'));
export const BookingTimeInfo = loadable(() => import(/* webpackChunkName: "InboxPage.Components" */ './BookingTimeInfo/BookingTimeInfo'));
export const BookingPanel = loadable(() => import(/* webpackChunkName: "ListingPage.Components" */ './BookingPanel/BookingPanel'));

export { default as Credits } from './Credits/Credits';
export { default as Discussion } from './Discussion/Discussion';
export { default as FilterPlain } from './FilterPlain/FilterPlain';
export { default as FilterPopup } from './FilterPopup/FilterPopup';
export const KeywordFilter = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './KeywordFilter/KeywordFilter'));
export const ListingCard = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './ListingCard/ListingCard'));
export const ManageListingCard = loadable(() => import(/* webpackChunkName: "ManageListingsPage.Components" */ './ManageListingCard/ManageListingCard'));
export const Map = loadable(() => import(/* webpackChunkName: "ListingPage.Components" */ './Map/Map'));
//export { default as OrderDiscussionPanel } from './OrderDiscussionPanel/OrderDiscussionPanel';
export const OwnListingLink = loadable(() => import(/* webpackChunkName: "LandingPage.Components" */ './OwnListingLink/OwnListingLink'));
export { default as Page } from './Page/Page';
export const PriceFilter = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './PriceFilter/PriceFilter'));
export { default as Reviews } from './Reviews/Reviews';
export const SavedCardDetails = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage.Components" */ './SavedCardDetails/SavedCardDetails'));
export const SearchFiltersMobile = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchFiltersMobile/SearchFiltersMobile'));
export const SearchFiltersPrimary = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchFiltersPrimary/SearchFiltersPrimary'));
export const SearchFiltersSecondary = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchFiltersSecondary/SearchFiltersSecondary'));
export const SearchMap = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchMap/SearchMap'));
export const SearchMapGroupLabel = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchMapGroupLabel/SearchMapGroupLabel'));
export const SearchMapInfoCard = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchMapInfoCard/SearchMapInfoCard'));
export const SearchMapPriceLabel = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchMapPriceLabel/SearchMapPriceLabel'));
export const SearchResultsPanel = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchResultsPanel/SearchResultsPanel'));
export const SearchFiltersCategories = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SearchFiltersCategories/SearchFiltersCategories'));
export const SelectMultipleFilter = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SelectMultipleFilter/SelectMultipleFilter'));
export const SelectSingleFilter = loadable(() => import(/* webpackChunkName: "SearchPage.Components" */ './SelectSingleFilter/SelectSingleFilter'));
export { default as SortBy } from './SortBy/SortBy';
export const StripeConnectAccountStatusBox = loadable(() => import(/* webpackChunkName: "StripePayoutPage.Components" */ './StripeConnectAccountStatusBox/StripeConnectAccountStatusBox'));
export const StripePaymentAddress = loadable(() => import(/* webpackChunkName: "CheckoutPage.Components" */ './StripePaymentAddress/StripePaymentAddress'));

export const UserCard = loadable(() => import(/* webpackChunkName: "ListingPage.Components" */ './UserCard/UserCard'));
export { default as AffiliateLinkShare } from './AffiliateLinkShare/AffiliateLinkShare'
export { default as CopyLinkbutton } from './CopyLinkButton/CopyLinkButton'
export { default as BackButton } from './BackButton/BackButton'
export { default as RecombeeRecommendations } from './RecombeeRecommendations/RecombeeRecommendations';
//////////////////////////////////////////////
// Page sections and modal content wrappers //
//////////////////////////////////////////////

export { default as LayoutWrapperAccountSettingsSideNav } from './LayoutWrapperAccountSettingsSideNav/LayoutWrapperAccountSettingsSideNav';
export {default as LoadableComponentErrorBoundary } from './LoadableComponentErrorBoundary/LoadableComponentErrorBoundary'
export { default as ModalMissingInformation } from './ModalMissingInformation/ModalMissingInformation';
export { default as RescheduleModal } from './RescheduleModal/RescheduleModal';
export { default as ReviewModal } from './ReviewModal/ReviewModal';
export const PrivacyPolicy = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage.Components" */ './PrivacyPolicy/PrivacyPolicy'));
export { default as TermsOfService } from './TermsOfService/TermsOfService';

export const EditListingAvailabilityPanel = loadable(() => import(/* webpackChunkName: "EditListingPage.Components" */ './EditListingAvailabilityPanel/EditListingAvailabilityPanel'));
export const EditListingDescriptionPanel = loadable(() => import(/* webpackChunkName: "EditListingPage.Components" */ './EditListingDescriptionPanel/EditListingDescriptionPanel'));
export const EditListingFeaturesPanel = loadable(() => import(/* webpackChunkName: "EditListingPage.Components" */ './EditListingFeaturesPanel/EditListingFeaturesPanel'));
export const EditListingLocationPanel = loadable(() => import(/* webpackChunkName: "EditListingPage.Components" */ './EditListingLocationPanel/EditListingLocationPanel'));
export const EditListingPhotosPanel = loadable(() => import(/* webpackChunkName: "EditListingPage.Components" */ './EditListingPhotosPanel/EditListingPhotosPanel'));
export const EditListingPoliciesPanel = loadable(() => import(/* webpackChunkName: "EditListingPage.Components" */ './EditListingPoliciesPanel/EditListingPoliciesPanel'));
export const EditListingPricingPanel = loadable(() => import(/* webpackChunkName: "EditListingPage.Components" */ './EditListingPricingPanel/EditListingPricingPanel'));
export const EditListingWizard = loadable(() => import(/* webpackChunkName: "EditListingPage.Components" */ './EditListingWizard/EditListingWizard'));

export { default as Footer } from './Footer/Footer';
export const SectionDestinations = loadable(() => import(/* webpackChunkName: "LandingPage.Components" */ './SectionDestinations/SectionDestinations'));
export { default as SectionThumbnailLinks } from './SectionThumbnailLinks/SectionThumbnailLinks';

export const Topbar = loadable(() => import(/* webpackChunkName: "Topbar" */ './Topbar/Topbar'));
export const TopbarDesktop = loadable(() => import(/* webpackChunkName: "TopbarDesktop" */ './TopbarDesktop/TopbarDesktop'));
export const TopbarMobileMenu = loadable(() => import(/* webpackChunkName: "TopbarMobileMenu" */ './TopbarMobileMenu/TopbarMobileMenu'));
export const TransactionPanel = loadable(() => import(/* webpackChunkName: "TransactionPage.Components" */ './TransactionPanel/TransactionPanel'));

export const VideoPlayer = loadable(() => import(/* webpackChunkName: "VideoPlayer" */ './VideoPlayer/VideoPlayer'));
export const HeroImageBackground = loadable(() => import(
    /* webpackChunkName: "HeroImageBackground" */ 
    /* webpackFetchPriority: "high" */
    './HeroImageBackground/HeroImageBackground'));
