import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from "react-router-dom";
import {
  Button
} from '..';
import { IconContext } from 'react-icons';
import {FaChevronLeft} from "react-icons/fa6";
import css from './BackButton.module.css';
import clsx from 'clsx';


const BackButton = props => {
  const { 
    rootClassName,
    disabled,
    message,
    showIcon
  } = props;

  // state
  const [mounted, setMounted] = useState(false);

  useEffect(()=>{
    setMounted(true)
  },[])

  const classes = clsx(rootClassName || css.root )
  const history = useHistory();
  const returnText = useIntl().formatMessage({id: "BackButton.backText" })

  const onHandleClick = () => {
    history.goBack()
  }

  const buttonDisabled = mounted ? disabled : true;
  return (
    <>
      <Button className={classes} disabled={buttonDisabled} onClick={onHandleClick}>
        <div>
          {showIcon ? <IconContext.Provider value={{ size: 24, style: { fill: 'none' } }}><FaChevronLeft /> </IconContext.Provider> : null}
          {message ? message : returnText}
        </div>
      </Button>
    </>
  );
}

BackButton.defaultProps = {
  showIcon: true,
  message: null
}

export default BackButton;