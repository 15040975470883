import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SAVE_QUICK_REPLIES_REQUEST = 'app/QuickRepliesPage/SAVE_QUICK_REPLIES_REQUEST';
export const SAVE_QUICK_REPLIES_SUCCESS = 'app/QuickRepliesPage/SAVE_QUICK_REPLIES_SUCCESS';
export const SAVE_QUICK_REPLIES_ERROR = 'app/QuickRepliesPage/SAVE_QUICK_REPLIES_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveQuickRepliesInProgress: false,
  saveQuickRepliesError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case SAVE_QUICK_REPLIES_REQUEST:
      return {
        ...state,
        saveQuickRepliesInProgress: true,
        saveQuickRepliesError: null,
      };
    case SAVE_QUICK_REPLIES_SUCCESS:
      return {
        ...state,
        // image: null,
        saveQuickRepliesInProgress: false,
      };
    case SAVE_QUICK_REPLIES_ERROR:
      return {
        ...state,
        // image: null,
        saveQuickRepliesInProgress: false,
        saveQuickRepliesError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveQuickRepliesRequest = params => ({
  type: SAVE_QUICK_REPLIES_REQUEST,
  payload: { params },
});
export const saveQuickRepliesSuccess = params => ({
  type: SAVE_QUICK_REPLIES_SUCCESS,
  payload: { params },
});
export const saveQuickRepliesError = error => ({
  type: SAVE_QUICK_REPLIES_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const saveQuickReplies = params => (dispatch, getState, sdk) => {
  dispatch(saveQuickRepliesRequest());
  const {
    quickReply1Title,
    currentQuickReply1Title,
    quickReply1Message,
    currentQuickReply1Message,
    quickReply2Title,
    currentQuickReply2Title,
    quickReply2Message,
    currentQuickReply2Message,
    quickReply3Title,
    currentQuickReply3Title,
    quickReply3Message,
    currentQuickReply3Message,
  } = params;

  const quickReply1TitleChanged = quickReply1Title !== currentQuickReply1Title;
  const quickReply1MessageChanged = quickReply1Message !== currentQuickReply1Message;
  const quickReply2TitleChanged = quickReply2Title !== currentQuickReply2Title;
  const quickReply2MessageChanged = quickReply2Message !== currentQuickReply2Message;
  const quickReply3TitleChanged = quickReply3Title !== currentQuickReply3Title;
  const quickReply3MessageChanged = quickReply3Message !== currentQuickReply3Message;

  if ( quickReply1TitleChanged || quickReply1MessageChanged || quickReply2TitleChanged || quickReply2MessageChanged || quickReply3TitleChanged || quickReply3MessageChanged) {
    return sdk.currentUser
      .updateProfile(
        {
          protectedData: {
            quickReplies: {
              quickReply1: {
                title: quickReply1Title,
                message: quickReply1Message,
              },
              quickReply2: {
                title: quickReply2Title,
                message: quickReply2Message,
              },
              quickReply3: {
                title: quickReply3Title,
                message: quickReply3Message,
              },
            },
          },
        },
        {
          expand: true,
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        }
      )
      .then(response => {
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        } else {
          dispatch(saveQuickRepliesSuccess());
        }

        const currentUser = entities[0];
        return currentUser;
      })
      .catch(e => {
        dispatch(saveQuickRepliesError(storableError(e)));
        throw e;
      });
    }
  };

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
