import recombee from 'recombee-js-api-client/dist/recombee-api-client';
import { getCookie, uuidPattern, listingPattern, recommIdPattern } from '../util/data';
import isbot from 'isbot';
import { klaviyoTrackAddToCart, klaviyoCreateProfile, klaviyoUpdateProfile, klaviyoTrackNewOrder, klaviyoTrackPageView, postToSlackAlerts, klaviyoGetProfile } from '../util/api';

export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }

  trackUserLogin(userid) {
    if (typeof window !== 'undefined') {
      console.log("User logged in: " + userid);
    }
  }

  trackUserLogout() {
    if (typeof window !== 'undefined') {
      console.log("User logged out!");
    }
  }
  trackCheckout(order){
    console.log("New order completed: " + JSON.stringify(order))
  }

  trackUserProperties(user) {
    console.log("User properties available: " + JSON.stringify(user.id));
  }
}

export class GoogleAnalyticsHandler {
  trackPageView(url) {
    console.log("URL: " + url)
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        //page_title: '<Page Title>', // For future implementation
        page_location: url,
        //page_path: '<Page Path>', // For future implementation
        send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_ID
      })
    }
  }

  trackUserLogin(userid) {
    if (typeof window !== 'undefined') {
      window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {'user_id': userid});
    }
  }

  trackUserLogout() {
    if (typeof window !== 'undefined') {
      window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID  , {'user_id': ''});
    }
  }
}

export class HeapAnalyticsHandler {
  trackUserLogin(userid) {
    if (typeof window !== 'undefined') {
      window.heap.identify(userid);
    }
  }

  trackUserLogout() {
    if (typeof window !== 'undefined') {
      window.heap.resetIdentity();
    }
  }

  trackUserProperties(user) {
    // User may be null if he is not logged in
    if (user !== null && typeof window !== 'undefined') {
      const protectedData = user.attributes.profile.protectedData || {};
      const userType = () => { 
          if ( protectedData !=={} && (protectedData.type?? false) ){
          switch (protectedData.type) {
            case '1':
              return "Customer"
            case '2':
              return "Tour Operator"
            case '3':
              return "Affiliate"      
            default:
              break;
          }
          return 'Unknown';
        }
      }
      window.heap.addUserProperties({
        UserType: userType(),
        marketingEmailOption: protectedData.marketingEmailOption ?? false,
        newBookingSmsNotification: protectedData.newBookingSmsNotification ?? false,
        newBookingWhatsappNotification: protectedData.newBookingWhatsappNotification ?? false,
        
      });
    }
  }
}
export class RecombeeHandler {  
  async trackUserLogin(userid) {
    let sessionId = document.cookie.match(/st-(.+?)-token/)[1]
    const currentUserUuid = getCookie('st_uuid')
    var client = new recombee.ApiClient(process.env.REACT_APP_RECOMBEE_DB, process.env.REACT_APP_RECOMBEE_PUBLIC_TOKEN, {region: 'eu-west'});
    try {
      // Try-catch block to prevent errors when temporary user didn't make any interactions, so not yet created in recombee.
      await client.send(new recombee.MergeUsers(currentUserUuid,sessionId, 'cascade_create=true'))
    }
    catch (e) { 
      console.log('Recombee: ', e)
    }
  }

  trackPageView(url) {
    var test = listingPattern.test(url)

    if (typeof window !== 'undefined' && listingPattern.test(url) && !isbot(navigator.userAgent)) {

      var client = new recombee.ApiClient(process.env.REACT_APP_RECOMBEE_DB, process.env.REACT_APP_RECOMBEE_PUBLIC_TOKEN, {region: 'eu-west'});

      // Get listingId (an uuid) from url
      const listingId = url.match(uuidPattern)[0]

      // Get "session" id from sharetribe cookie
      let sessionId = document.cookie.match(/st-(.+?)-token/)[1]

      // Get current user UUID
      const currentUserUuid = getCookie('st_uuid')
      // Get recommId (an uuid) from the url, if available.
      var recommId = recommIdPattern.test(url) ? url.match(recommIdPattern)[1] : '' 
      var rId = recommIdPattern.test(recommId)
      // Use the current user UUID if present, otherwise use the sessionId to associate with the pageview.
      if (recommId) {
        if (uuidPattern.test(listingId) && uuidPattern.test(currentUserUuid)) {
          client.send(new recombee.AddDetailView(currentUserUuid, listingId, {
            'recommId': recommId
          }));
        } else if (uuidPattern.test(listingId) && uuidPattern.test(sessionId)) {
          client.send(new recombee.AddDetailView(sessionId, listingId))
        }
      } else {
        if (uuidPattern.test(listingId) && uuidPattern.test(currentUserUuid)) {
          client.send(new recombee.AddDetailView(currentUserUuid, listingId));
        } else if (uuidPattern.test(listingId) && uuidPattern.test(sessionId)) {
          client.send(new recombee.AddDetailView(sessionId, listingId))
        }
      }  
    }
  }

  trackAddToCart(order){
    if (typeof window !== 'undefined' && !isbot(navigator.userAgent)) {
      var client = new recombee.ApiClient(process.env.REACT_APP_RECOMBEE_DB, process.env.REACT_APP_RECOMBEE_PUBLIC_TOKEN, {region: 'eu-west'});
      
      // Get listingId
      const listingId = order.listing.id.uuid

      // Get "session" id from sharetribe cookie
      let sessionId = document.cookie.match(/st-(.+?)-token/)[1]

      // Get current user UUID
      const currentUserUuid = getCookie('st_uuid')

      if (uuidPattern.test(listingId) && uuidPattern.test(currentUserUuid)) {
        client.send(new recombee.AddCartAddition(currentUserUuid, listingId))
      } else if (uuidPattern.test(listingId) && uuidPattern.test(sessionId)) {
        client.send(new recombee.AddCartAddition(sessionId, listingId))
      }
    }
  }
}

export class KlaviyoHandler {

  async trackSignupRequest(userData) {
    if (typeof window !== 'undefined' && !isbot(navigator.userAgent)) {
      const { sharetribeId, firstName, lastName, email, phoneNumber, userType, marketingEmailOption } = userData

      klaviyoCreateProfile({ externalId: sharetribeId, firstName: firstName, lastName: lastName, email: email, phoneNumber: phoneNumber, userType: userType, marketingEmailOption: marketingEmailOption })
        .catch(error => {
          console.log(error)
          klaviyoGetProfile({ email: email })
            .then(response => {
              var id = ''
              if (typeof response.data.data[0] != 'undefined') {
                id = response.data.data[0].id
              }
              klaviyoUpdateProfile({ id: id, firstName: firstName, lastName: lastName, userType: userType, marketingEmailOption: marketingEmailOption });
            })
        })
    }
  }
  
  trackPageView(url) {
    if (typeof window !== 'undefined' && listingPattern.test(url) && !isbot(navigator.userAgent)) {

      // Get listingId (an uuid) from url
      const listingId = url.match(uuidPattern)[0]

      // Get "session" id from sharetribe cookie
      const sessionId = document.cookie.match(/st-(.+?)-token/)[1]

      // Get current user UUID
      const currentUserUuid = getCookie('st_uuid')
      
      // Use the current user UUID if present, otherwise use the sessionId to associate with the pageview.
        if (uuidPattern.test(listingId) && uuidPattern.test(currentUserUuid)) {
          klaviyoTrackPageView({itemId: listingId, userId: currentUserUuid})
        }
    }
  }

  trackAddToCart(order){
    if (typeof window !== 'undefined' && !isbot(navigator.userAgent)) {
      // Get listingId

      const listingId = order.listing.id.uuid
      const date = order.bookingData.bookingStartDate.date
      const title = order.listing.attributes
      const price = order.listing.attributes.price.amount / 100

      // Get "session" id from sharetribe cookie
      let sessionId = document.cookie.match(/st-(.+?)-token/)[1]

      // Get current user UUID
      const currentUserUuid = getCookie('st_uuid')

      if (uuidPattern.test(listingId) && uuidPattern.test(currentUserUuid)) {
        klaviyoTrackAddToCart({userId: currentUserUuid, itemId: listingId, bookingDate: date, itemName: title, itemPrice: price})
      } 
    }
  }

  trackCheckout(order,orderParams){
    if (typeof window !== 'undefined' && !isbot(navigator.userAgent)) {

      const listingId = orderParams.listingId.uuid
      const date = order.booking.attributes.displayStart
      const price = order.attributes.payinTotal.amount / 100
      const quantityAdult = orderParams.bookingQuantityAdult
      const quantityChild = orderParams.bookingQuantityChild
      const quantityInfant = orderParams.bookingQuantityInfant

      // Get "session" id from sharetribe cookie
      const sessionId = document.cookie.match(/st-(.+?)-token/)[1]

      // Get current user UUID
      const currentUserUuid = getCookie('st_uuid')

      if (uuidPattern.test(listingId) && uuidPattern.test(currentUserUuid)) {
        klaviyoTrackNewOrder({userId: currentUserUuid, itemId: listingId, bookingDate: date, itemPrice: price, quantityAdult: quantityAdult, quantityChild: quantityChild, quantityInfant: quantityInfant})
      } 
    }
  }
}
  export class SlackHandler {
    //trackSignUp
    trackSignupRequest(userData){
      
      if (typeof window !== 'undefined' && window.location.hostname != 'localhost' && window.location.hostname != 'test.tourly.pt' && !isbot(navigator.userAgent)) {

        let account = "Undefined"
        let newSignupSlackNotificationMessage = ''

        switch (userData.userType) {
            case 1 : case '1':
              account = 'End customer'
              break;
            case 2 : case '2':
              account = 'Tour Operator. Company name: ' + userData.cname + '.'
              break;
            case 3 : case '3':
              account = 'Affiliate. Affiliate name: ' + userData.cname + '.'
              break;
            default:
              break;
          }
        
        newSignupSlackNotificationMessage = 'New user signup\nName: ' + userData.firstName + ' ' + userData.lastName + '\nEmail: ' + userData.email + '\nAccount type: '+ account + '\nEmail marketing enabled: ' + userData.marketingEmailOption
        postToSlackAlerts({ message: newSignupSlackNotificationMessage })
      }
    } 
  }