import React, { useState, useContext, useEffect } from 'react';
import { createStyles, UnstyledButton, Menu, Image, Group, rem } from '@mantine/core';
import { LanguageContext } from '../../context/LanguageContext';
import usePrevious from '../../hooks/usePreviousHook';

import english from '../../assets/languageIcons/english.png'
// import french from '../../assets/languageIcons/french.png'
// import german from '../../assets/languageIcons/german.png'
// import italian from '../../assets/languageIcons/italian.png'
// import polish from '../../assets/languageIcons/polish.png'
import portugal from '../../assets/languageIcons/portugal.png'

const data =[
  { label: 'English',
    image: english,
    code: 'en'
  },
  { label: 'Portuguese',
    image: portugal,
    code: 'pt'
  },
];

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    width: rem(75),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    height: '72px'
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}));

const LangSelector = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [opened, setOpened] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(data[0].code)
  const { classes } = useStyles({ opened });
  const previous = usePrevious(selectedLanguage)
  const items = data.map((item) => (
    <Menu.Item
      icon={<Image src={item.image} width={18} height={18} />}
      onClick={() => setLanguage(item.code)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));
  
  useEffect(() =>{
    let activeLanguage = data.find(lang =>{ return lang.code === language })
    setSelectedLanguage(activeLanguage)
  },[])

  useEffect(() =>{
    if(previous !== language){
      let selectedLanguage = data.find(item => item.code === language)
      setSelectedLanguage(selectedLanguage)
    }
  },[language])

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="200px"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Group spacing="xs">
            <Image src={selectedLanguage.image} width={22} height={22} />
            {/* <span className={classes.label}>{selected.label}</span> */}
          </Group>
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" style={{transform: 'rotate(0deg)'}}>
            <path d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
          </svg>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
}

export default LangSelector;