import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getAffiliateBalance } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/AffiliatePayoutPage/SET_INITIAL_VALUES';

export const GET_BALANCE_REQUEST = 'app/AffiliatePayoutPage/GET_BALANCE_REQUEST';
export const GET_BALANCE_SUCCESS = 'app/AffiliatePayoutPage/GET_BALANCE_SUCCESS';
export const GET_BALANCE_ERROR = 'app/AffiliatePayoutPage/GET_BALANCE_ERROR';

// ================ Reducer ================ //

const initialState = {
  fromReturnURL: false,
  balance: 0,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    case GET_BALANCE_REQUEST:
      return { ...state, getBalanceError: null, userToken: payload.userToken };
    case GET_BALANCE_SUCCESS:
      return { ...state, balance: payload };
    case GET_BALANCE_ERROR:
      return { ...state, getBalanceError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const getBalanceRequest = userToken => ({
  type: GET_BALANCE_REQUEST,
  payload: { userToken },
});

export const getBalanceSuccess = balance => ({
  type: GET_BALANCE_SUCCESS,
  payload: { balance },
});

export const getBalanceError = () => ({
  type: GET_BALANCE_ERROR,
});

// ================ Thunks ================ //

export const getBalance = userToken => (dispatch, getState, sdk) => {
  dispatch(getBalanceRequest(userToken));
  getAffiliateBalance({ affiliateId: userToken })
    .then(response => {
      const balanceInEur = response.data["EUR"] ? response.data["EUR"] : 0;
      dispatch(getBalanceSuccess(balanceInEur));
      return balanceInEur;
    })
    .catch(e => {
      dispatch(getBalanceError(storableError(e)))
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;

    dispatch(getBalance(currentUser.attributes.profile.privateData.id))
  });
};
