/* 
*   Loads <lang>.json files under translations dynamically, allowing webpack to split each json file in a different chunk.
*/
const getTranslatedMessages = (lang) => {
  const loadTranslation = async (lang) => {
    const messages = await import(/* webpackChunkName: "Translations.[request]" */ `./${lang}.json`);
    return messages;
  };
  const messages = loadTranslation(lang).then( (translations) => {
    return translations;
  });
  return messages;
};

export default getTranslatedMessages;
