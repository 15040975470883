import React, { useState } from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import { Modal,DangerButton, SecondaryButton, ExternalLink } from '..'
import clsx from 'clsx';
import css from './Credits.module.css';

const Credits = props => {
  const {
    className,
    rootClassName,
  } = props;

  const [isOpen, setOpen] = useState(false)
  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    // console.log('Toggling ModalInMobile - currently:', componentId, scrollingDisabled);
  };

  const handleOpen = e => {
    setOpen(true);
  };

  const handleClose = e => {
    setOpen(false);
  };

  const CreditsModal = props =>{
    return (
      <Modal
        id="creditsModal"
        containerClassName={css.modalContainer}
        onClose={() => {
          setOpen(false);
        }}
        isModalOpenOnMobile={isOpen}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        isOpen={isOpen}
      >
        <div>
          <h1>
            <FormattedMessage id="Credits.title" />
          </h1>
          <h4>
            <FormattedMessage id="Credits.attribution" />
          </h4>
          <div className={css.credits}>
            <ul className={css.creditsList}>
              <li className={css.creditsItem}> 
                <ExternalLink
                  key="Vecteezy"
                  href={'https://www.vecteezy.com/vector-art/123309-free-christmas-icons-vector'}
                  title={'Tree Vectors by Vecteezy'}
                >
                  Tree Vectors by Vecteezy
                </ExternalLink>
              </li>
              <li className={css.creditsItem}>
                <ExternalLink
                  key="Freepik1"
                  href={'https://www.freepik.com/free-vector/floral-christmas-ornaments_973603.htm'}
                  title={'Floral Christmas ornaments image by Freepik'}
                >
                  Floral Christmas ornaments image by Freepik
                </ExternalLink>
              </li>
              <li className={css.creditsItem}>
                <ExternalLink
                  key="Freepik1"
                  href={'https://www.freepik.com/free-vector/floral-christmas-ornaments_973603.htm'}
                  title={'Christmas borders image set by Freepik'}
                >
                  Christmas borders image set by Freepik
                </ExternalLink>
              </li>
            </ul>
         
          </div>
          <div className={css.closeButton}>
            <SecondaryButton
              onClick={() => {
                handleClose();
              }}
            >
              <FormattedMessage id="Credits.closeModal" />
            </SecondaryButton>
          </div>
        </div>
      </Modal>
    )
  }
  const classes = clsx(rootClassName || css.links, className);
  return (
    <span className={css.creditsRoot}>
      <span className={classes}>
        <span className={css.creditsLinkWrapper} onClick={e => handleOpen(e)}>
           <FormattedMessage id="Credits.openButton" />
        </span>
        <CreditsModal />
      </span>
    </span>
  )
}

export default Credits;