import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import {
  SecondaryButton
} from '../../components';
import config from '../../config'
import css from './CopyLinkButton.module.css';

const CopyLinkButton = props => {

  const {
    affiliateURL,
    isDesktop,
    disabled,
    buttonClicked,
    parentCallBack
  } = props;
  
  // state
  const [mounted, setMounted] = useState(false);


  useEffect(()=>{
    setMounted(true)
  },[])

  // copy the affiliate URL to the clipboard
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(affiliateURL) // pass the referral url to this function
  }
  const test = 'test'

  const onHandleClick = () => {
    parentCallBack(test)
    const desktopDevice = isDesktop
    const siteTitle = config.siteTitle

    // desktop device 
    if (desktopDevice ) {
        // copy link to clipboard
        copyToClipBoard();
        //setCopiedText('Link Copied')

    } else {
      // mobile or tablet device
      // check if the device supports OS Share sheet
      if(navigator.Share) {
        // We can use share() to share the data!

        navigator
        .share({
          title: `${siteTitle}`,
          url:  affiliateURL // 
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch(error => {
          console.error('Something went wrong sharing the link', error);
        });
      } else {
        // fallback to clipboard 
          copyToClipBoard();
      }

    }
  }
  const buttonDisabled = mounted ? disabled : true;
  const linkCopiedText = (<FormattedMessage id={"CopyLinkButton.linkCopiedText"} />);
  const linkButtonText = (<FormattedMessage id={"CopyLinkButton.linkButtonText"} />);
  return (
    <div className={css.mainWrapper}>
      <div className={css.wrapper}>
        <SecondaryButton  className={css.copyLinkButton} onClick={onHandleClick} disabled={buttonDisabled} >
          <span>{linkButtonText}</span>
        </SecondaryButton>
      </div>
      <p className={css.linkCopiedText}>{buttonClicked ? linkCopiedText : ''}</p>
    </div>
  );
}

const {  string, bool } = PropTypes;

CopyLinkButton.defaultProps = {
  affiliateLink: null,
  isDesktop: null,
  disabled: false
};

CopyLinkButton.propTypes = {
  affiliateLink: string,
  isDesktop: bool,
  disabled: bool,
};

export default CopyLinkButton;
