import React, { useState, useEffect } from "react";
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { getCookie, uuidPattern } from '../../util/data';
import { array, string, bool } from 'prop-types';
import css from './RecombeeRecommendations.module.css'
import clsx from 'clsx';
import EmblaCarousel from './EmblaCarousel'
import { useLocation } from "react-router-dom"

function RecombeeRecommendationsComponent(props) {
  const {
    listingId,
    intl,
    showRecomms,
    largeTitle,
    isListing,
    useEmbla,
  } = props

  const classes = clsx(isListing ? css.listingCarouselWrapper : css.carouselWrapper );
  let userIdentification = '';
  const count = 8; // number of recommended items to show
  const carouselsToShow = []
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [itemToUser, setItemsToUser] = useState(null)
  let sessionId = '';
  let currentUserUuid = '';

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const region = queryParameters.get('region')

  if (typeof window !== 'undefined') {
    // Get "session" id from sharetribe cookie
    sessionId = document.cookie.match(/st-(.+?)-token/) ? document.cookie.match(/st-(.+?)-token/)[1] : '';
    // Get current user UUID
    currentUserUuid = getCookie('st_uuid')

    userIdentification = 
     uuidPattern.test(currentUserUuid) ? currentUserUuid 
                              : uuidPattern.test(sessionId) ? sessionId : '' ;
  }                            

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Get "session" id from sharetribe cookie
      let sessionId = document.cookie.match(/st-(.+?)-token/) ? document.cookie.match(/st-(.+?)-token/)[1] : '';
      // Get current user UUID
      currentUserUuid = getCookie('st_uuid')

      userIdentification = 
       uuidPattern.test(currentUserUuid) ? currentUserUuid 
                                : uuidPattern.test(sessionId) ? sessionId : '' ;

      if(showRecomms) {
        showRecomms.map(showRecomm => {

          if(showRecomm.showScenario){
            let scenario = showRecomm.scenario

            switch (scenario) {
              case 'listing-similar':
              case'listing-bestsellers':
              case'listing-upsell':
              case'same-category':
                setItemsToUser(false)
                break;
              case 'checkout-crosssell':
              case'homepage-popular':
              case'homepage-recently-viewed':
              case'homepage-upsell':
               setItemsToUser(true)
                break;
              default:
                //RecommItemToUser(scenario);
                break;
            }
          }
          return
        })
      }
    }
  },[]);

  Object.values(showRecomms).forEach(item=>{
    if(item.showScenario){
      carouselsToShow.push({
        scenario: item.scenario,
        itemToUser: itemToUser,
        userIdentification: userIdentification
      })
    }
  });

  return (
    <>
      {
        carouselsToShow.map(item =>{
          return(
            useEmbla ? 
              <EmblaCarousel
                key={item.scenario}
                intl={intl}
                count={count}
                classes={classes}
                itemToUser={itemToUser}
                scenario={item.scenario}
                userIdentification={userIdentification}
                listingId={listingId}
                largeTitle={largeTitle}
                mobile={mobile ? 1 : 2 }
                region={region}
              />  : null  
          )
        })
      }
    </>
  );
}

RecombeeRecommendationsComponent.defaultProps = {
  rootClassName: null,
  className: null,
  showSimilar: false,
  showBestSellers: true,
  showUpsell: false,
  scenarios: [],
  largeTitle: false,
  isListing: false,
  useEmbla: false,
};

RecombeeRecommendationsComponent.propTypes = {
  rootClassName: string,
  className: string,
  showSimilar: bool,
  showBestSellers: bool,
  showUpsell: bool,
  scenarios: array,
  largeTitle: bool,


  // from injectIntl
  intl: intlShape.isRequired,
};

const RecombeeRecommendations = injectIntl(RecombeeRecommendationsComponent)
export default React.memo(RecombeeRecommendations);