import React, { useState, useEffect } from 'react';
//import { arrayOf, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, Modal, Button, CopyLinkbutton } from '..';

import config from '../../config';

import { useLocation } from 'react-router-dom';

import { desktopDevice } from '../../util/userAgent';

import css from './AffiliateLinkShare.module.css';

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  // We are just checking the value for now
  //console.log('Toggling ModalInMobile - currently:', componentId, scrollingDisabled);
};

const AffiliateLinkShare = props => {
  const { currentUser, isAuthenticated, listing, buttonId } = props;

  //states
  const [mounted, setMounted] = useState(false);
  const [isOpen, setOpen] = useState(false); // modal state
  const [buttonType, setButtonType] = useState(null);
  const [isDesktop, setIsDesktop] = useState(false);
  const [buttonClicked, setButtonClick] = useState(false);

  // update state after component has loaded
  useEffect(() => {
    setMounted(true);
    setIsDesktop(desktopDevice());
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;

  let user_type = '';
  const affiliate_url_param = config.affiliateURLParam; // ?ref= paramater of the URL

  const marketPlaceURL = config.canonicalRootURL; // marketplace URL
  const listingURL = useLocation().pathname; // Listing URL

  const fullListingURL = marketPlaceURL + listingURL + affiliate_url_param;
  const fullMaketplaceURL = marketPlaceURL + affiliate_url_param;

  // Text strings for the components
  const modalContentMarketplace = (
    <FormattedMessage id={'AffiliateLinkShare.ModalContentMarketplace'} />
  );
  const modalContentListing = <FormattedMessage id={'AffiliateLinkShare.ModalContentListing'} />;
  const affiliateListingText = <FormattedMessage id={'AffiliateLinkShare.ListingButton'} />;
  const afiliateMenuText = <FormattedMessage id={'AffiliateLinkShare.menuBarText'} />;
  const dashboardBtnText = <FormattedMessage id={'AffiliateLinkShare.dashboardLinkText'} />;

  const buttonText = buttonId => {
    switch (buttonId) {
      case 'menu':
        return afiliateMenuText;

      case 'dashboard':
        return dashboardBtnText;

      case 'listingButton':
        return affiliateListingText;
      default:
        return null;
    }
  };

  const affiliateURL = () => {
    if (authenticatedOnClientSide) {
      if (currentUser) {
        user_type = currentUser.attributes.profile.protectedData.type === '3' ? true : false;

        if (user_type === true) {
          //SA token
          const affiliateToken = currentUser.attributes.profile.privateData.token;

          //create affiliate link
          const affiliateLinkURL =
            buttonType === 'dashboard' || buttonType === 'menu'
              ? fullMaketplaceURL + affiliateToken
              : fullListingURL + affiliateToken;
          // return the affiliate link
          return affiliateLinkURL;
        }
      }
    } else {
      return null;
    }
  };

  const handleOpen = e => {
    setOpen(true);
    setButtonType(e.currentTarget.id);
    affiliateURL();
    modalText(e.currentTarget.id);
  };

  const modalText = () => {
    if (mounted) {
      if (buttonType === 'menu' || buttonType === 'dashboard') {
        return modalContentMarketplace;
      } else {
        return modalContentListing;
      }
    }
  };

  //Callback that handles the state change of the copylink button
  const handleCallback = () => {
    setButtonClick(true);
  };

  return (
    <div className={css.affiliateLinkWrapper}>
      {listing ? (
        <Button
          className={
            buttonId === 'dashboard'
              ? css.dashboardAffiliateLinkButton
              : css.listingAffiliateLinkButton
          }
          children={buttonText(buttonId)}
          id={buttonId}
          onClick={e => handleOpen(e)}
        />
      ) : (
        <InlineTextButton id={buttonId} className={css.signupLink} onClick={e => handleOpen(e)}>
          <span className={css.signup}>{buttonText(buttonId)}</span>
        </InlineTextButton>
      )}
      <Modal
        id="affiateLinkModal"
        containerClassName={css.modalContainer}
        onClose={() => {
          setOpen(false);
          setButtonClick(false);
        }}
        isModalOpenOnMobile={isOpen}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        isOpen={isOpen}
      >
        <div>
          <h1>
            <FormattedMessage id="AffiliateLinkShare.ModalHeading" />
          </h1>
          <div>
            <p className={css.affiliateModalContent}>{modalText()}</p>
            <CopyLinkbutton
              affiliateURL={affiliateURL()}
              isDesktop={isDesktop}
              buttonClicked={buttonClicked}
              parentCallBack={handleCallback}
            />
          </div>
        </div>
      </Modal>
    </div>
  ); // end return
};

export default AffiliateLinkShare;
