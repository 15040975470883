import React, {useEffect,useState} from 'react';
import { oneOf, string } from 'prop-types';
import clsx from 'clsx';

import css from './Logo.module.css';

import config from '../../config';


const getLogo = (showChristmas, isMobile) => {
  var logo = null;
  if (showChristmas) {
    logo = import(/* webpackChunkName: "ChristmasLogoImage" */ './desktop-logo-image-tourly-ball.png');
  } else if (isMobile) {
    logo = import(/* webpackChunkName: "MobileLogoImage" */ './mobile-logo-image-tourly.png');
  } else {
    logo = import(/* webpackChunkName: "DesktopLogoImage" */ './desktop-logo-image-tourly.png');
  }
  return logo;
}

const Logo = props => {
  const { className, format, ...rest } = props;
  const [logo, setLogo] = useState(null);
  const isMobile = format !== 'desktop';
  const classes = clsx(className, { [css.logoMobile]: isMobile });
  const logoImage = getLogo(config.showChristmasTheme, isMobile);
  const logoWidth = config.showChristmasTheme
    ? 153
    : isMobile
    ? 153
    : 100;
  const logoHeight = config.showChristmasTheme
    ? 49
    : isMobile
    ? 49
    : 32;
    useEffect(() => {
      logoImage.then((logo) => {
        setLogo(logo.default);
      });
      }, []);
    
      return logo ? (
        <img
          className={classes}
          src={logo}
          alt={config.siteTitle}
          width={logoWidth}
          height={logoHeight}
          {...rest}
        />
        ) : (<div><p>loading...</p></div>)
};

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;