// ================ Action types ================ //

export const LOCATION_CHANGED = 'app/Routing/LOCATION_CHANGED';

// ================ Reducer ================ //

const initialState = {
  currentLocation: null,
  currentCanonicalUrl: null,
  currentAffiliateRef: null,
  utm_parameters: {}
};

export default function routingReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOCATION_CHANGED:
      return {
        ...state,
        currentLocation: payload.location,
        currentCanonicalUrl: payload.canonicalUrl,
        currentAffiliateRef:payload.affiliateRef,
        utm_parameters: payload.utm_parameters
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const locationChanged = (location, canonicalUrl, affiliateRef, utm_parameters) => ({
  type: LOCATION_CHANGED,
  payload: { location, canonicalUrl, affiliateRef, utm_parameters },
});
