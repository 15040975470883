import React from 'react';
import { ResponsiveImage } from '../components';
import { types as sdkTypes } from '../util/sdkLoader';
import { Helmet } from 'react-helmet-async';

const { UUID } = sdkTypes;

// These are the required variants we ask for each image
// Note the first variant is used as src for older devices
// that don't know srcset... these should be smaller devices so
// a lower resolution seems appropriate
export const LISTING_CARD_VARIANTS = [
  'landscape-crop', // 400x267
  'landscape-crop2x', // 800x533
  'landscape-crop4x', // 1600x1066
];

const variantsToSrcSetMappings = [
  {
    variant: 'landscape-crop',
    params: { qs: 'w=400&q=45&auto=enhance,compress,format&blend-pad=10', width: 400, height: 267 },
  },
  {
    variant: 'landscape-crop2x',
    params: { qs: 'w=800&q=35&auto=enhance,compress,format&blend-pad=20', width: 800, height: 533 },
  },
  {
    variant: 'landscape-crop3x',
    params: {
      qs: 'w=1200&q=25&auto=enhance,compress,format&blend-pad=30',
      width: 1200,
      height: 801,
    },
  },
  {
    variant: 'landscape-crop4x',
    params: {
      qs: 'w=1600&q=10&auto=enhance,compress,format&blend-pad=40',
      width: 1600,
      height: 1066,
    },
  },
  {
    variant: 'landscape-crop5x',
    params: {
      qs: 'w=2000&q=10&auto=enhance,compress,format&blend-pad=50',
      width: 2000,
      height: 1335,
    },
  },
  {
    variant: 'landscape-crop6x',
    params: {
      qs: 'w=2400&q=10&auto=enhance,compress,format&blend-pad=60',
      width: 2400,
      height: 1602,
    },
  },
  {
    variant: 'scaled-small',
    params: { qs: 'w=320&q=60&auto=enhance,compress,format&blend-pad=10', width: 320, height: 320 },
  },
  {
    variant: 'scaled-medium',
    params: { qs: 'w=750&q=40&auto=enhance,compress,format&blend-pad=10', width: 750, height: 750 },
  },
  {
    variant: 'scaled-large',
    params: {
      qs: 'w=1024&q=30&auto=enhance,compress,format&blend-pad=10',
      width: 1024,
      height: 1024,
    },
  },
  {
    variant: 'scaled-xlarge',
    params: {
      qs: 'w=2400&q=10&auto=enhance,compress,format&blend-pad=10',
      width: 2400,
      height: 2400,
    },
  },
  {
    variant: 'x1',
    params: {
      qs: 'q=50&auto=enhance,compress,format&blend-pad=20',
      width: -1, // should not be used
      height: -1, // should not be used
    },
  },
  {
    variant: 'x1.75',
    params: {
      qs: 'q=30&auto=enhance,compress,format&blend-pad=40',
      width: -1, // should not be used
      height: -1, // should not be used
    },
  },
  {
    variant: 'x2',
    params: {
      qs: 'q=30&auto=enhance,compress,format&blend-pad=40',
      width: -1, // should not be used
      height: -1, // should not be used
    },
  },
  {
    variant: 'x3',
    params: {
      qs: 'q=20&auto=enhance,compress,format&blend-pad=60',
      width: -1, // should not be used
      height: -1, // should not be used
    },
  },
  {
    variant: 'grid-300x200',
    params: {
      qs: 'q=60&auto=enhance,compress,format&blend-pad=30',
      width: 300, // should not be used
      height: 200, // should not be used
    },
  },
  {
    variant: 'grid-600x400',
    params: {
      qs: 'q=40&auto=enhance,compress,format&blend-pad=30',
      width: 600, // should not be used
      height: 400, // should not be used
    },
  },
];

const EMPTY_IMG_DATA = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
const imgixBaseUrl = 'https://tourly-media.imgix.net';
const mediaFolder = 'media';

export const HERO_SIZES = 'auto';
export const CARD_SIZES = 'auto';
export const StandardDPRVariants = ['x1', 'x1.75', 'x2'];

export const getTourlyProxyServer = (origin) => {
  const proxyServer = new URL(`${origin}/image-proxy`);
  proxyServer.port =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_API_SERVER_PORT
      : proxyServer.port;
  return proxyServer;
};

export const getTourlyImageServer = () => {
  return new URL(`${imgixBaseUrl}/${mediaFolder}`);
};

export const processVariantsForImage = (proxied, width, height, origin, image, variants) => {
  let variantsJson = {};
  if (origin.endsWith('/')) origin = origin.slice(0, -1); // Remove trailing slash if found
  variants.forEach((v) => {
    const params = variantsToSrcSetMappings.find((m) => m.variant === v).params;

    const { variantWidth, variantHeight, targetUrl } = buildVariant(proxied, origin, image, v, width, height, params);

    variantsJson[v] = {
      name: v,
      width: variantWidth,
      height: variantHeight,
      url: targetUrl.toString(),
    };
  });
  return {
    id: new UUID(image),
    type: 'image',
    attributes: {
      variants: variantsJson,
    },
  };
};

function buildVariant(proxied, origin, imageUrl, v, width, height, params) {
  if (origin.endsWith('/')) origin = origin.slice(0, -1); // Remove trailing slash if found
  let targetUrl = '';
  const variantWidth =
    v === 'x1'
      ? width
      : v === 'x1.75'
      ? Math.floor(parseInt(width) * 1.75)
      : v === 'x2'
      ? parseInt(width) * 2
      : v === 'x3'
      ? parseInt(width) * 3
      : params.width;
  const variantHeight = height!=-1 ?
    v === 'x1'
      ? height
      : v === 'x1.75'
      ? Math.floor(parseInt(height) * 1.75)
      : v === 'x2'
      ? parseInt(height) * 2
      : v === 'x3'
      ? parseInt(height) * 3
      : -1
  : params.height;
  
  if (proxied) {
    targetUrl = new URL(`${origin}`);
    const encodedUrl = encodeURIComponent(imageUrl);
    targetUrl.searchParams.append('url', encodedUrl);
    targetUrl.searchParams.append('params', params.qs + 
    '&w=' + variantWidth + 
    (variantHeight!=-1 ? '&h=' + variantHeight : ''));
  } else {
    targetUrl = new URL(`${origin}/${imageUrl}`);
    params.qs.split('&').map((p) => {
      const [pname, pvalue] = p.split('=');
      if (pname && pvalue) targetUrl.searchParams.append(pname, pvalue);
      return p;
    });
    targetUrl.searchParams.append('w', variantWidth);
    if (variantHeight!=-1)
      targetUrl.searchParams.append('h', variantHeight);
  }

  return { variantWidth, variantHeight, targetUrl };
}

function buildSourceSetFromVariants(imageVariants, variants, additionalParams = '') {
  // Remove & if additionalParams starts with &
  if (additionalParams.charAt(0) === '&') additionalParams = additionalParams.slice(1);
  const src =
    !imageVariants || Object.entries(imageVariants).length === 0
      ? EMPTY_IMG_DATA
      : addParamsToBaseURL(Object.entries(imageVariants)[0][1].url, additionalParams);

  const srcSet = variants
    .map((variantName) => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      // Add additional parameters
      const url = addParamsToBaseURL(variant.url, additionalParams);
      return `${url} ${variant.width}w`;
    })
    .filter((v) => v != null)
    .join(', ');
  return { src, srcSet };

  function addParamsToBaseURL(url, additionalParams) {
    const baseUrl = new URL(url);
    if (additionalParams!='') {
      if (baseUrl.searchParams.has('params')) {
        baseUrl.searchParams.set(
          'params',
          baseUrl.searchParams.get('params') + '&' + encodeURIComponent(additionalParams)
        );
      } else {
          additionalParams.split('&').forEach((p) => {
          const [pname, pvalue] = p.split('=');
          if (pname && pvalue) baseUrl.searchParams.append(pname, pvalue);
        });
      }
    }
    return baseUrl.toString();
  }
}

export const PreloadHighPriorityImage = (props) => {
  const { variants, image, sizes, fetchpriority, additionalParams, ...rest } = props;
  const imageVariants = image.attributes.variants;

  const { src, srcSet } = buildSourceSetFromVariants(imageVariants, variants, additionalParams);

  if (fetchpriority === 'high') {
    try {
      if (window) {
         if (window.document.head.querySelectorAll(`link[rel='preload'][as='image'][href="${src}"]`).length === 0) {
          const link = document.createElement('link');
          link.setAttribute("rel", "preload");
          link.setAttribute('as', 'image');
          link.setAttribute('imagesrcset', srcSet);
          link.setAttribute('href', src);
          link.setAttribute('imagesizes', sizes);
          link.setAttribute('fetchpriority', fetchpriority);
          window.document.head.prepend(link);
         }
        return null;
      }
    } catch (error) {
      console.error("Error preloading images by writting directly to header element... will revert to using normal preload approach.");
    }
    return (
      <Helmet prioritizeSeoTags>
          <link
            rel="preload"
            as="image"
            imagesrcset={srcSet}
            href={src}
            imagesizes={sizes}
            fetchpriority={fetchpriority}
          />
      </Helmet>
    );
  } else {
    return null;
  }
};

export const TourlyResponsiveImage = (props) => {
  const { variants, image, sizes, additionalParams, ...rest } = props;
  const imageVariants = image.attributes.variants;
  const { src, srcSet } = buildSourceSetFromVariants(imageVariants, variants, additionalParams);

  return (
    <ResponsiveImage
      src={src}
      image={image}
      variants={variants}
      srcSet={srcSet}
      sizes={sizes}
      {...rest}
    />
  );
};
