import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useEffect, useState } from 'react';
import { IconSpinner } from '../components';
import config from '../config';

export const pagesUsingStripe = [
    "CheckoutPage",
    "PaymentMethodsPage",
    "StripePayoutPage",
    "StripePayoutOnboardingPage"
];

const StripeLoader = (props) => {
  const [loaded, setLoaded] = useState(!!window.Stripe);
  const [stripeCheckInterval, setstripeCheckInterval] = useState(null);
  const {onStripeLoaded} = props;
  const CHECK_FOR_STRIPE_MS = 100;

  const checkStripe = () => {
    //console.log("[StripeLoader]: checkStripe && window.Stripe=", !!window.Stripe)
    if (!!window.Stripe) {
      setLoaded(true);
    }
  }

  useEffect (() => {
    if (loaded && onStripeLoaded) {
      //console.log("[StripeLoader]: Calling onStripeLoaded");
      onStripeLoaded();
    }
    return () => {
      //console.log("[StripeLoader]: Clearing interval on useEffect[loaded] !");
      clearInterval(stripeCheckInterval); // Stop further checks once Stripe is loaded
  	};
  }, [loaded])

  useEffect(() => {
    const loadLibrary = async () => {
      try {
        // Dynamically import the library (replace 'library-name' with the actual library name)
        if (typeof window !== 'undefined' && typeof window.Stripe === 'undefined') {
          //console.log("[StripeLoader]: useEffect loading Stripe...")
          await loadStripe(config.stripe.publishableKey);
        }
         // Set up an interval to keep checking for Stripe until it's defined
         setstripeCheckInterval(setInterval(() => {checkStripe()}, CHECK_FOR_STRIPE_MS));
      } catch (error) {
        console.error('[StripeLoader]: Error loading Stripe:', error);
      }
    };
    //console.log("[StripeLoader]: useEffect[] loaded=",loaded," && window.Stripe=", !!window.Stripe)
    if (!loaded) {
        loadLibrary();
    }
    return () => {
      //console.log("[StripeLoader]: Clearing interval on useEffect[] !");
      clearInterval(stripeCheckInterval); // Stop further checks once Stripe is loaded
  	};
  }, []); // Empty dependency array ensures that the effect runs only once, similar to componentDidMount

  // Library is still loading, you can show a loading indicator or some other fallback
  //console.log("[StripeLoader]: returning loading stripe...")
  return <IconSpinner />;
};

export default StripeLoader; 