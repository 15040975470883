import loadable from '@loadable/component'; // needed to lazy load components

export const BookingTimeForm = loadable(() => import(/* webpackChunkName: "BookingForms" */ './BookingTimeForm/BookingTimeForm'));
export const ContactDetailsForm = loadable(() => import(/* webpackChunkName: "ContactDetailsForm" */ './ContactDetailsForm/ContactDetailsForm'));
export const EditListingAvailabilityExceptionForm = loadable(() => import(/* webpackChunkName: "EditListingForms" */ './EditListingAvailabilityExceptionForm/EditListingAvailabilityExceptionForm'));
export const EditListingAvailabilityPlanForm = loadable(() => import(/* webpackChunkName: "EditListingForms" */ './EditListingAvailabilityPlanForm/EditListingAvailabilityPlanForm'));
export const ConfirmSignupForm = loadable(() => import(/* webpackChunkName: "SignupForms" */ './ConfirmSignupForm/ConfirmSignupForm'));
export const EditListingDescriptionForm = loadable(() => import(/* webpackChunkName: "EditListingForms" */ './EditListingDescriptionForm/EditListingDescriptionForm'));
export const EditListingFeaturesForm = loadable(() => import(/* webpackChunkName: "EditListingForms" */ './EditListingFeaturesForm/EditListingFeaturesForm'));
export const EditListingLocationForm = loadable(() => import(/* webpackChunkName: "EditListingForms" */ './EditListingLocationForm/EditListingLocationForm'));
export const EditListingPhotosForm = loadable(() => import(/* webpackChunkName: "EditListingForms" */ './EditListingPhotosForm/EditListingPhotosForm'));
export const EditListingPoliciesForm = loadable(() => import(/* webpackChunkName: "EditListingForms" */ './EditListingPoliciesForm/EditListingPoliciesForm'));
export const EditListingPricingForm = loadable(() => import(/* webpackChunkName: "EditListingForms" */ './EditListingPricingForm/EditListingPricingForm'));
export const EmailVerificationForm = loadable(() => import(/* webpackChunkName: "LoginForms" */ './EmailVerificationForm/EmailVerificationForm'));
export const EnquiryForm = loadable(() => import(/* webpackChunkName: "EnquiryForm" */ './EnquiryForm/EnquiryForm'));
export const FilterForm = loadable(() => import(/* webpackChunkName: "FilterForms" */ './FilterForm/FilterForm'));
export const LocationSearchForm = loadable(() => import(/* webpackChunkName: "SearchForms" */ './LocationSearchForm/LocationSearchForm'));
export const LoginForm = loadable(() => import(/* webpackChunkName: "LoginForms" */ './LoginForm/LoginForm'));
export const PasswordChangeForm = loadable(() => import(/* webpackChunkName: "PasswordForms" */ './PasswordChangeForm/PasswordChangeForm'));
export const PasswordRecoveryForm = loadable(() => import(/* webpackChunkName: "PasswordForms" */ './PasswordRecoveryForm/PasswordRecoveryForm'));
export const PasswordResetForm = loadable(() => import(/* webpackChunkName: "PasswordForms" */ './PasswordResetForm/PasswordResetForm'));
export const PaymentMethodsForm = loadable(() => import(/* webpackChunkName: "PaymentForms" */ './PaymentMethodsForm/PaymentMethodsForm'));
export const PriceFilterForm = loadable(() => import(/* webpackChunkName: "FilterForms" */ './PriceFilterForm/PriceFilterForm'));
export const ProfileSettingsForm = loadable(() => import(/* webpackChunkName: "ProfileSettingsForm" */ './ProfileSettingsForm/ProfileSettingsForm'));
export const RescheduleBookingForm = loadable(() => import(/* webpackChunkName: "BookingForms" */ './RescheduleBookingTimeForm/RescheduleBookingForm'));
export const QuickRepliesForm = loadable(() => import(/* webpackChunkName: "QuickRepliesForm" */ './QuickRepliesForm/QuickRepliesForm'));
export const ReviewForm = loadable(() => import(/* webpackChunkName: "ReviewForm" */ './ReviewForm/ReviewForm'));
export const SendMessageForm = loadable(() => import(/* webpackChunkName: "SendMessageForm" */ './SendMessageForm/SendMessageForm'));
export const SignupForm = loadable(() => import(/* webpackChunkName: "SignupForms" */ './SignupForm/SignupForm'));
export const StripePaymentForm = loadable(() => import(/* webpackChunkName: "PaymentForms" */ './StripePaymentForm/StripePaymentForm'));
export const SignupStripePaymentForm = loadable(() => import(/* webpackChunkName: "SignupForms" */ './StripePaymentForm/SignupStripePaymentForm'));
export const StripeConnectAccountForm = loadable(() => import(/* webpackChunkName: "StripeConnectAccountForm" */ './StripeConnectAccountForm/StripeConnectAccountForm'));
export const TopbarSearchForm = loadable(() => import(/* webpackChunkName: "SearchForms" */ './TopbarSearchForm/TopbarSearchForm'));
