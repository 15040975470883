import React from 'react';
import { arrayOf, string } from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';

const ResponsiveImage = props => {
  const { className, rootClassName, alt, noImageMessage, image, variants,fetchpriority,srcSet,src, sizes, ...rest } = props;
  const classes = clsx(rootClassName || css.root, className);

  if (image == null || variants.length === 0) {
    const noImageClasses = clsx(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  let imgProps = {};
  // These are the cases where we have built a responsive image with SrcSet
  if (srcSet != null || src!=null) {
    imgProps = {
      fetchpriority: fetchpriority ?? 'auto',
      'data-sizes': sizes,
      srcSet: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
      src: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
      className: classes + ' lazyload',
      'data-src': src,
      'data-srcset': srcSet === null || srcSet === undefined || srcSet === '' ? src : srcSet,
      ...rest,
    };
  } else {
    // These are the cases where are are loading the srcSet from variants provided
    //  - typically used in backend TO functionality (e.g. add images to listing)
    const imageVariants = image.attributes.variants;

    const builtSrcSet = variants
      .map((variantName) => {
        const variant = imageVariants[variantName];

        if (!variant) {
          // Variant not available (most like just not loaded yet)
          return null;
        }
        return `${variant.url} ${variant.width}w`;
      })
      .filter((v) => v != null)
      .join(', ');

    imgProps = {
      className: classes,
      srcSet: builtSrcSet,
      ...rest,
    };
  }   

  return <img alt={alt} {...imgProps} />;
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  noImageMessage: null,
  fetchpriority: null,
  srcSet : null,
  src: null
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: propTypes.image,
  variants: arrayOf(string).isRequired,
  noImageMessage: string,
  fetchpriority: string,
  srcSet: string,
  src:string
};

export default ResponsiveImage;
