import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser, currentUser } from './user.duck';
import { createUserWithIdp } from '../util/api';
import { registerAffiliate, addAffiliateToProgram, addAffiliateToTier, klaviyoCreateProfile } from '../util/api';
//import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
import * as log from '../util/log';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const CHECKOUT_PAGE_LOGIN_REQUEST = 'app/Auth/CHECKOUT_PAGE_LOGIN_REQUEST';
export const CHECKOUT_PAGE_LOGIN_SUCCESS = 'app/Auth/CHECKOUT_PAGE_LOGIN_SUCCESS';
export const CHECKOUT_PAGE_LOGIN_ERROR = 'app/Auth/CHECKOUT_PAGE_LOGIN_ERROR';

export const CREATE_AFFILIATE_REQUEST = 'app/Auth/CREATE_AFFILIATE_REQUEST';
export const CREATE_AFFILIATE_SUCCESS = 'app/Auth/CREATE_AFFILIATE_SUCCESS';
export const CREATE_AFFILIATE_ERROR = 'app/Auth/CREATE_AFFILIATE_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

export const CHECKOUT_PAGE_SIGNUP_REQUEST = 'app/Auth/CHECKOUT_PAGE_SIGNUP_REQUEST';
export const CHECKOUT_PAGE_SIGNUP_SUCCESS = 'app/Auth/CHECKOUT_PAGE_SIGNUP_SUCCESS';
export const CHECKOUT_PAGE_SIGNUP_ERROR = 'app/Auth/CHECKOUT_PAGE_SIGNUP_ERROR';
export const CHECKOUT_PAGE_SIGNUP_CLEAR_ERROR = 'app/Auth/CHECKOUT_PAGE_SIGNUP_CLEAR_ERROR';

export const AFFILIATE_SIGNUP_REQUEST = 'app/Auth/AFFILIATE_SIGNUP_REQUEST';
export const AFFILIATE_SIGNUP_SUCCESS = 'app/Auth/AFFILIATE_SIGNUP_SUCCESS';
export const AFFILIATE_SIGNUP_ERROR = 'app/Auth/AFFILIATE_SIGNUP_ERROR';

export const AFFILIATE_DETAILS_SAVING_ERROR = 'app/Auth/AFFILIATE_DETAILS_SAVING_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';
export const USER_LOGIN = 'app/USER_LOGIN';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
  // affiliate signup
  affiliateSignupError: null,
  affiliateSignupInProgress: false,

  // registering affiliate
  affiliateDetailsSavingError: null,
  affiliateDetailsSaveInProgess: false,
  affiliateDetailsChanged: false,
  affiliate: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case CHECKOUT_PAGE_LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case CHECKOUT_PAGE_LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case CHECKOUT_PAGE_LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };
    
    case CHECKOUT_PAGE_SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case CHECKOUT_PAGE_SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case CHECKOUT_PAGE_SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };
    case CHECKOUT_PAGE_SIGNUP_CLEAR_ERROR:
      return { ...state, signupInProgress: false, signupError: null };

    case AFFILIATE_SIGNUP_REQUEST:
      return { ...state, signupInProgress: false, affiliateSignupInProgress: true, affiliateSignupError: null };
    case AFFILIATE_SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false, affiliateSignupInProgress: false, affiliateSignupError: null, affiliate: action.payload };
    case AFFILIATE_SIGNUP_ERROR:
      return { ...state, signupInProgress: false, affiliateSignupInProgress: false, affiliateSignupError: action.payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const checkoutPage_loginRequest = () => ({ type: CHECKOUT_PAGE_LOGIN_REQUEST });
export const checkoutPage_loginSuccess = () => ({ type: CHECKOUT_PAGE_LOGIN_SUCCESS });
export const checkoutPage_loginError = error => ({ type: CHECKOUT_PAGE_LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = (params) => ({ type: SIGNUP_SUCCESS, payload: params });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const checkoutPage_signupRequest = () => ({ type: CHECKOUT_PAGE_SIGNUP_REQUEST });
export const checkoutPage_signupSuccess = (params) => ({ type: CHECKOUT_PAGE_SIGNUP_SUCCESS, payload: params });
export const checkoutPage_signupError = error => ({ type: CHECKOUT_PAGE_SIGNUP_ERROR, payload: error, error: true });
export const checkoutPage_clear_signupError = () => ({ type: CHECKOUT_PAGE_SIGNUP_CLEAR_ERROR });

export const affiliateSignupRequest = () => ({ type: AFFILIATE_SIGNUP_REQUEST });
export const affiliateSignupSuccess = (affiliate) => ({ type: AFFILIATE_SIGNUP_SUCCESS, payload: affiliate });
export const affiliateSignupError = error => ({ type: AFFILIATE_SIGNUP_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });
export const userLogin = (userid,uuid) => ({ type: USER_LOGIN, payload: userid });

export const affiliateDetailsSavingError = error => ({
  type: AFFILIATE_DETAILS_SAVING_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .then(() => dispatch(userLogin(username)))
    //.then(() => dispatch(requestSaveAffiliateIdAndToken()) )
    .catch(e => dispatch(loginError(storableError(e))));
};

export const checkoutPage_login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(checkoutPage_loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(checkoutPage_loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .then(() => dispatch(userLogin(username)))
    .catch(e => dispatch(checkoutPage_loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest(params));
  let createUserParams = {};
  const { email, password, firstName, lastName, cname, affiliateId, affiliateToken, affiliatePassword, marketingEmailOption, ...rest } = params;
  //check if the user is a type 3, then add the privateData or not to the createUserParams object
  if (params.type === "3") {
    createUserParams = isEmpty(rest)
      ? { email, password, firstName, lastName, publicData: { cname }, privateData: { id: affiliateId, token: affiliateToken, tapfiliatePassword: affiliatePassword } }
      : { email, password, firstName, lastName, protectedData: { ...rest, marketingEmailOption: marketingEmailOption }, publicData: { cname }, privateData: { id: affiliateId, token: affiliateToken, tapfiliatePassword: affiliatePassword } };
  } else {
    createUserParams = isEmpty(rest)
      ? { email, password, firstName, lastName, protectedData: { marketingEmailOption: marketingEmailOption }, publicData: { cname } }
      : { email, password, firstName, lastName, protectedData: { ...rest, marketingEmailOption: marketingEmailOption }, publicData: { cname } };
  }

  /*
    A new promise is created, this is for the new signup logic which is the following:
    1 - create an affiliate if the user signing up is of the type 3
    2 - depending on the response of step 1 we disptch the affiateSignupSuccess action,
        and update createUser Partams with the affiliate id and token in the case of a user with type 3.
    3 - Create a user on Sharetribe and dispatch the signupSuccess action.
    4 - login the new user, since the api does not do this automatically.
  */
  return new Promise((resolve, reject) => {
    // create affiliate parmeters
    const { email, type, cname } = params;

    // check if the user is a Sales Associate
    if (type === "3") {
      dispatch(affiliateSignupRequest());

      let firstNameCompany = '';
      let lastNameCompany = '';
      const affiliateCompanyName = cname.trim() // trim whitespace
      let CompanyNameArray = affiliateCompanyName.split(" ")
      //if length is 1, it means that we have only one word for company name
      // so we spit the word in the middle and use this as firstname and last name
      if (Array.isArray(CompanyNameArray) && CompanyNameArray.length === 1) {
        var middle = Math.ceil(affiliateCompanyName.length / 2)
        firstNameCompany = affiliateCompanyName.slice(0, middle)
        lastNameCompany = affiliateCompanyName.slice(middle)
      } else {
        firstNameCompany = CompanyNameArray[0]
        lastNameCompany = CompanyNameArray.shift() && CompanyNameArray.join('')
        // eslint-disable-next-line no-unused-vars
        const [, ...rest] = CompanyNameArray.join('')
      }
      // api call to create affiliate on Tapfiliate
      //resolve(registerAffiliate({ firstName: firstNameCompany, lastName: lastNameCompany, email: email }))
      const response = registerAffiliate({ firstName: firstNameCompany, lastName: lastNameCompany, email: email })
      resolve(response)
      //console.log('affiliate creation error: ',e)}

    }
    else {
      // for normal users and Tour Operators the promise returns null
      //console.log('normal user')
      //resolve(null)
      resolve('normal user')
    }
  })
    .catch(e => { dispatch(affiliateSignupError(storableError(e))) })
    .then((response) => {
      // in case the response data isn't null the parameters in the createUserParam object are updated
      if (response !== 'normal user') {
        dispatch(affiliateSignupSuccess(response.data))
        createUserParams.privateData.id = response.data.id;
        createUserParams.privateData.tapfiliatePassword = response.data.password;
      }

      return createUserParams
    })
    .then((createUserParams) => {
      if (createUserParams.protectedData.type === "3") {
        addAffiliateToProgram({ affiliateId: createUserParams.privateData.id })
          .then((response) => {
              const createUserParams = { 
                privateData: {
                  referralLink: response.data.referral_link.link,
                  token: response.data.referral_link.link.match(/.*ref=(.*)/)[1]
                  }
                }
              sdk.currentUser.updateProfile(createUserParams)
          })
          .catch(e => {
            dispatch(affiliateSignupError(storableError(e)));
            //console.log('Error adding affiliate to program: ', e);
          })
      }
      return createUserParams
    })
    .then((createUserParams) => {
      if (createUserParams.protectedData.type === "3") {
        addAffiliateToTier({ affiliateId: createUserParams.privateData.id })
          .catch(e => {
            dispatch(affiliateSignupError(storableError(e)));
            console.log('Error adding affiliate to tier: ', e);
          })
      }
      return createUserParams
    })

    .then((createUserParams) => {
      // When the parameter marketingEmailOption is true we set the sms notification true for TOs
      if(createUserParams.protectedData.marketingEmailOption === true){
        
        // Create newBookingSmsNotification / newBookingWhatsappNotification for TO's
        if (createUserParams.protectedData.type === "2") {
          createUserParams.protectedData.newBookingSmsNotification = true;
          createUserParams.protectedData.newBookingWhatsappNotification = false;
        }

      } // end if(createUserParams.protectedData.marketingEmailOption === true)
      else {
        // Create newBookingSmsNotification / newBookingWhatsappNotification - set to false - for TO's
        if (createUserParams.protectedData.type === "2") {
          createUserParams.protectedData.newBookingSmsNotification = false;
          createUserParams.protectedData.newBookingWhatsappNotification = false;
        }
      }
      return createUserParams
    })
    .then((createUserParams) => {
      // create the user on sharetribe
      return sdk.currentUser
        .create(createUserParams)
        .then((response) => {
          // The 'params' object is used for the analytics/handlers.
          const params = {};
          params.sharetribeId = response.data.data.id.uuid
          params.firstName = createUserParams.firstName
          params.lastName = createUserParams.lastName
          params.email = createUserParams.email
          params.phoneNumber = createUserParams.protectedData.phoneNumber
          params.userType = createUserParams.protectedData.type
          params.marketingEmailOption = createUserParams.protectedData.marketingEmailOption
          typeof createUserParams.publicData.cname != 'undefined' ? params.cname = createUserParams.publicData.cname : '';

          dispatch(signupSuccess(params))
          return response.data
        })

    })
    .then(
      // perfom the login with the new user
      () => {
        dispatch(login(email, password))
        return 'success'
      }
    )
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        ...params
      });
      //throw new Error(e)
    });
};

// TODO: Update signupWithIdp function to support creating users on Tapfilliate
export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  return createUserWithIdp(params)
    .then(res => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};

export const checkoutPage_signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(checkoutPage_signupRequest(params));
  let createUserParams = {};
  const { email, password, firstName, lastName, marketingEmailOption, ...rest } = params;
  createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName, protectedData: { marketingEmailOption: marketingEmailOption } }
    : { email, password, firstName, lastName, protectedData: { ...rest, marketingEmailOption: marketingEmailOption } };

  //create the user on sharetribe
  return new Promise((resolve, reject) => {
    resolve(sdk.currentUser
      .create(createUserParams))
  }) 
  .then((response) => {
    // The 'params' object is used for the analytics/handlers.
    const params = {};
    params.sharetribeId = response.data.data.id.uuid
    params.firstName = createUserParams.firstName
    params.lastName = createUserParams.lastName
    params.email = createUserParams.email
    params.phoneNumber = createUserParams.protectedData.phoneNumber
    params.userType = createUserParams.protectedData.type
    params.marketingEmailOption = createUserParams.protectedData.marketingEmailOption

    dispatch(checkoutPage_signupSuccess(params))
  })
  .catch(e => {
    //console.log('marketingEmailOption === false create user failed')
    dispatch(signupError(storableError(e)));
    log.error(e, 'signup-failed', {
      ...params
    });
    throw new Error(e)
  });
};

export const clearSignupError = () => dispatch => {
  return dispatch(checkoutPage_clear_signupError())
};
