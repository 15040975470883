import React, { Component } from 'react';
import { array, string, func, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import clsx from 'clsx';
import { propTypes } from '../../util/types';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug, encodeLatLng } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconFreeCancellation,
  IconClock,
  IconBus,
  IconShield,
  IconFullStar,
  IconHalfStar,
  IconEmptyStar,
  NamedLink,
} from '..';
import {
  TourlyResponsiveImage,
  processVariantsForImage,
  CARD_SIZES,
  StandardDPRVariants,
  getTourlyProxyServer
} from '../../util/responsiveImagesHelper';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './RecombeeRecommendations.module.css';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory } from 'react-router-dom';
import { formatMoney } from '../../util/currency';
const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};


export const RecombeeListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    title,
    imageUrl,
    setActiveListing,
    listingUrl,
    listingId,
    duration,
    pickupIncluded,
    price,
    filtersConfig,
    recommId,
    width
  } = props;
  const classes = clsx(rootClassName || css.root, className);

  const slug = title != null ? createSlug(title) : '';
  const id = listingId != null ? listingId : '';
  const listingDuration = duration != null ? duration : '-';

  const firstImage = imageUrl != null ? imageUrl : '-';

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = pickupIncluded
    ? getCertificateInfo(certificateOptions, pickupIncluded ? 'yes': 'no')
    : null;
  const history = useHistory();
  // const duration = publicData.duration;
  // const discount =
  //   typeof listing.attributes.publicData.discountPercentage !== 'undefined'
  //     ? listing.attributes.publicData.discountPercentage
  //     : 0;

  // const googleRating =
  //   typeof listing.attributes.publicData.googleRating !== 'undefined'
  //     ? listing.attributes.publicData.googleRating
  //     : 0;

  // const discountedPrice = Object.create(price);
  // discountedPrice.amount = (100 + discount) * 0.01 * discountedPrice.amount;
  // const { formattedPrice, priceTitle } = priceData(price, intl);
  // const priceDiscountedValue = priceData(
  //   new Money((100 + discount) * 0.01 * price.amount, price.currency),
  //   intl
  // );

  // const unitType = config.bookingUnitType;
  // const isNightly = unitType === LINE_ITEM_NIGHT;
  // const isDaily = unitType === LINE_ITEM_DAY;

  // const unitTranslationKey =
  //   publicData && publicData.groupTour
  //     ? 'ListingCard.perGroup'
  //     : isNightly
  //     ? 'ListingCard.perNight'
  //     : isDaily
  //     ? 'ListingCard.perDay'
  //     : 'ListingCard.perUnit';

  let convertedPrice =  new Money( (price*100), config.currency );
  const { formattedPrice, priceTitle } = priceData(convertedPrice, intl);

  const to = recommId !== null ? { search: "recommId=" + recommId } : null;
  const routes = routeConfiguration();
 
  const handleClick = () => {
    if(typeof(window) !== undefined ){
      window.scrollTo(0, 0);
    }
    // Redirect to ListingPage
    history.push(
      createResourceLocatorString(
        'ListingPage',
        routes,
        { id, slug, listingDuration, firstImage },
        { recommId }
      )
    );
  }
  
  const slideAspectRatio = 3 / 2;
  const proxyServer = getTourlyProxyServer(window.location.origin);
  const processedVariants = processVariantsForImage(
    true,
    width,
    width / slideAspectRatio,
    proxyServer.toString(),
    imageUrl,
    StandardDPRVariants
  );

  return (
    <div className={classes} onClick={handleClick}>
      <div className={css.rootForImage} style={{ height: `${width / slideAspectRatio}px` }}>
        <a href={`/l/${id}/?recommId=${recommId}`} onClick={e => e.preventDefault()}>
          <TourlyResponsiveImage
            alt={title}
            image={processedVariants}
            variants={StandardDPRVariants}
            sizes={CARD_SIZES}
          />
        </a>
      </div>
      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          {/* <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div> */}
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div>
            <IconFreeCancellation />
            <span className={css.cancellationInfo}>
              <FormattedMessage id="ListingPage.FreeCancellation" />
            </span>
          </div>
          {duration !== null ? (
            <div>
              <IconClock />
              <span className={css.durationInfo}>
                {intl.formatMessage({ id: 'EditListingDescriptionForm.duration.' + duration })}
              </span>
            </div>
          ) : null}
          {certificate && !certificate.hideFromListingInfo ? (
            <div>
              <IconBus />
              <span className={css.durationInfo}>
                {intl.formatMessage({ id: 'ListingPage.OfferPickupPoint' })}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};


RecombeeListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
  width: null
};


RecombeeListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  setActiveListing: func,
  width: number
};

const RecombeeListingCard = RecombeeListingCardComponent
export default injectIntl(React.memo(RecombeeListingCard));
