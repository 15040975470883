import React from 'react';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { number, shape} from 'prop-types';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import clsx from 'clsx';
import { IconContext } from 'react-icons';
import { FaPhone } from 'react-icons/fa6';
// import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  // IconSocialMediaTwitter,
  IconSocialMediaLinkedIn,
  Logo,
  ExternalLink,
  NamedLink,
  Credits
} from '../../components';
import acceptedCards from './images/payment-methods.svg';

import css from './Footer.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteLinkedInPage } = config;
  // const siteTwitterHandle = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToLinkedIn = intl.formatMessage({ id: 'Footer.goToLinkedIn' });
  // const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instagramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const linkedInLink = siteLinkedInPage ? (
    <ExternalLink
      key="linkToLinkedIn"
      href={siteLinkedInPage}
      className={css.icon}
      title={goToLinkedIn}
    >
      <IconSocialMediaLinkedIn />
    </ExternalLink>
  ) : null;

  // const twitterLink = siteTwitterPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <IconSocialMediaTwitter />
  //   </ExternalLink>
  // ) : null;

  return [fbLink, instagramLink, linkedInLink].filter(v => v != null);
};

const date = new Date();

const Footer = props => {
  const { rootClassName, className, intl, viewport } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = clsx(rootClassName || css.root, className);
  const { siteElgoritmo } = config;

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  
  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="MainPage" className={css.logoLink}>
                <span className={css.logo}>
                {viewport.width > 0 ?
                  (<Logo format={isMobileLayout ? "mobile" : "desktop"} className={css.logoFooter} />)
                  : null}
                </span>
              </NamedLink>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className={css.contactInfoMobile}>
              <div className={css.someLinksMobile}>{socialMediaLinks}</div>
              <div>
                <ul>
                  {/* <li className={css.contactInfo}>
                  <a href="mailto:team@tourly.pt">
                    <FormattedMessage id="Footer.contactEmail" />
                  </a>
                </li> */}
                  <li className={css.contactInfo} itemProp="telephone">
                    <IconContext.Provider value={{ className: css.phoneIcon }}>
                      <FaPhone />{' '}
                    </IconContext.Provider>
                    <a href="tel:+351308810200">
                      <FormattedMessage id="Footer.contactNumber" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={css.someLinks}>
              <div>{socialMediaLinks}</div>
              <div>
                <ul>
                  {/* <li className={css.contactInfo}>
                    <a href="mailto:team@tourly.pt">
                      <FormattedMessage id="Footer.contactEmail" />
                    </a>
                  </li> */}
                  <li className={css.callUsAt}>
                    <IconContext.Provider value={{ className: css.phoneIcon }}>
                      <FaPhone />
                    </IconContext.Provider>
                    <FormattedMessage id="Footer.callUsAt" />
                  </li>
                  <li className={css.contactInfo} itemProp="telephone">
                    <a href="tel:+351308810200">
                      <FormattedMessage id="Footer.contactNumber" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="LandingPageSA" className={css.link}>
                    <FormattedMessage id="Footer.toAffiliateRegisterPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="LandingPageTO" className={css.link}>
                    <FormattedMessage id="Footer.toTORegisterPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="FAQPage" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="UnderConstructionPage" className={css.link}>
                    <FormattedMessage id="Footer.blog" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactUsPage" className={css.link}>
                    <FormattedMessage id="Footer.toContactUsPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.paymentInfo}>
              <img
                className={css.acceptedCards}
                src={acceptedCards}
                alt="Accepted credit cards: Master, Visa, Discover, Diners and Amex, powered by Stripe"
                width="180px"
                height="110px"
              />
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" /> {date.getFullYear()}
            </NamedLink>

            <ExternalLink href={siteElgoritmo} className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.elgoritmo" />
            </ExternalLink>

            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
              <Credits />
            </div>
          </div>
        </div>
        <div className={css.copyrightAndTerms}>
          <p className={css.organizationCopyright}>
            <NamedLink name="LandingPage" className={css.copyrightLink}>
              <FormattedMessage id="Footer.copyright" /> {date.getFullYear()}
            </NamedLink>
            <span> | </span>
            <span>
              <ExternalLink href={siteElgoritmo} className={css.organizationCopyright}>
                <FormattedMessage id="Footer.elgoritmo" />
              </ExternalLink>
            </span>
            <span> | </span>
            <span>
              <Credits />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const FooterComponent = compose(
  withViewport,
  injectIntl
)(Footer);

export default React.memo(FooterComponent);
