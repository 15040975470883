import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import clsx from 'clsx';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, selectedPageName, currentUser } = props;
  const classes = clsx(rootClassName || css.root, className);

  let tabs = [
    {
      text: <FormattedMessage id="UserNav.profileSettingsPage" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.contactDetailsPage" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  //Only display the manage listings link when the user is a tour operator
  if (currentUser !== null && currentUser !== undefined) {
    //check that the user is a Tour Operator
    if (currentUser.attributes.profile.protectedData.type === '2') {
      // tabs.unshift({
      //   text: <FormattedMessage id="UserNav.newListing" />,
      //   selected: selectedPageName === 'NewListingPage',
      //   linkProps: {
      //     name: 'NewListingPage',
      //   },
      // });

      //add the manage listings button to the beginning of the tabs array
      tabs.unshift({
        text: <FormattedMessage id="ManageListingsPage.yourListings" />,
        selected: selectedPageName === 'ManageListingsPage',
        linkProps: {
          name: 'ManageListingsPage',
        },
      });
    }
    //check that the user is a Sales Associate
    if (currentUser.attributes.profile.protectedData.type === '3') {
      //add the manage listings button to the beginning of the tabs array
      tabs.unshift({
        text: <FormattedMessage id="UserNav.dashboard" />,
        selected: selectedPageName === 'AffiliateDashboardPage',
        linkProps: {
          name: 'AffiliateDashboardPage',
        },
      });
    }
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

export default UserNav;
