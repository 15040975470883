import React, {useEffect} from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import clsx from 'clsx';
import { Modal } from '../../components';
import { RescheduleBookingForm } from '../../forms';

import css from './RescheduleModal.module.css';

const RescheduleModal = props => {
  const {
    intl,
    listing,
    onCloseModal,
    isOpen,
    monthlyTimeSlots,
    onFetchTimeSlots,
    timeZone,
    currentTransaction,
    rescheduleInProgress,
    rescheduleSaleError,
    onSubmitRescheduleBooking,
    transactionState,
    transactionRole,
    rescheduleBookingSuccess
  } = props;
  const TODAY = new Date();

  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    //console.log('Toggling ModalInMobile - currently:', componentId, scrollingDisabled);
  };

  const startDatePlaceholder = currentTransaction && currentTransaction.booking !== null ? currentTransaction.booking.attributes.start: TODAY;
  const endDatePlaceholder = currentTransaction && currentTransaction.booking !== null ? currentTransaction.booking.attributes.end: TODAY;
  const listingId = currentTransaction.listing.id

  return (
    <Modal
      id="rescheduleModal"
      contentClassName={css.modalContent}
      containerClassName={css.modalContainer}
      onClose={onCloseModal}
      isModalOpenOnMobile={isOpen}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      isOpen={isOpen}
    >
      <div>
        <h1>
          <FormattedMessage id="TransactionPanel.rescheduleTitle" />
        </h1>
        <h4>
          <FormattedMessage id="TransactionPanel.rescheduleMessage" />
        </h4>
        <RescheduleBookingForm
          formId="RescheduleBookingForm"
          onSubmit={(values) => { onSubmitRescheduleBooking(values) }}
          timeZone={timeZone}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          bookingStartDate={startDatePlaceholder}
          bookingEndDate={endDatePlaceholder}
          listingId={listingId}
          rescheduleInProgress={rescheduleInProgress}
          rescheduleSaleError={rescheduleSaleError}
          transactionState={transactionState}
          transactionRole={transactionRole}
          onCloseModal={onCloseModal}
          rescheduleBookingSuccess={rescheduleBookingSuccess}
        />
      </div>
    </Modal>
  );
};

export default RescheduleModal;