import React from 'react';
import { func, node, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import clsx from 'clsx';
import { ValidationError } from '../../components';
import NumericInput from 'react-numeric-input';
import { debounce } from 'lodash'
import css from './FieldQuantity.module.css';

const handleChange = (propsOnChange, inputOnChange) => event => {
  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (propsOnChange) {
    // "handleChange" function is attached to the low level <select> component
    // value of the element needs to be picked from target
    // const value = event.nativeEvent.target.value;
    const value = event;
    propsOnChange(value);
  }
  // Notify Final Form that the input has changed.
  // (Final Form knows how to deal with synthetic events of React.)
  inputOnChange(event);
};

const FieldQuantityComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = clsx(selectClassName, css.select, {
    [css.selectSuccess]: input.value && valid,
    [css.selectError]: hasError,
  });

  const { onChange: inputOnChange, value } = input;
  const selectProps = {
    className: selectClasses,
    id,
    onChange: debounce(handleChange(onChange, inputOnChange),400),
    value,
    ...rest,
  };
  const classes = clsx(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={id} className={css.label}>
          {label}
        </label>
      ) : null}
      <NumericInput
        {...selectProps}
        mobile
        strict
        onKeyDown={e => e.preventDefault()}
        style={{
          btn: { background: 'none', borderColor: 'none', borderStyle: 'none', cursor: 'pointer' },
          'input:not(.form-control)': { border: '', borderRadius: 'none' },
          'btn:hover': { background: 'none' },
          'btn:active': { background: 'none' },
        }}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldQuantityComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

FieldQuantityComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  onChange: func,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldQuantity = props => {
  return <Field component={FieldQuantityComponent} {...props} />;
};

export default FieldQuantity;
